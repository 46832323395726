import * as React from "react";
import {Checkbox, FormControlLabel, Radio} from "@mui/material";
import {createElement, ReactNode} from "react";

interface SimpleRadioProps {
	label: ReactNode;
	selected: boolean;
	onChange: (checked: boolean) => void;
}

export function SimpleRadio(props: SimpleRadioProps) {
	const {label, selected, onChange} = props;

	return (
		<FormControlLabel
			label={label}
			control={<Radio checked={selected} onChange={(event) => onChange(event.target.checked)} />}
		/>
	);
}
