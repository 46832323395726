import {PersonRounded, Visibility, VisibilityOff} from "@mui/icons-material";
import {Alert, Box, Button, Divider, Grid, IconButton, InputAdornment, Link} from "@mui/material";
import {CountrySelect, findCountry, PhonePrefixSelect, TextField} from "@variocube/app-ui";
import {createElement, FormEvent, useCallback, useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {gs} from "../../consts";
import {ipInfo} from "../../domain/ipinfo";
import {useSignUpApi} from "../../domain/signups";
import {getSignupDomain, usePublicApi} from "../../domain/tenants";
import {SignUpRequest} from "../../domain/types";
import {useLocalization} from "../../i18n";
import {InfoCard} from "../../layout/InfoCard";
import {MobileContainer} from "../../layout/MobileContainer";
import {Typography} from "../../layout/typography";

enum SignUpState {
	Form,
	EmailVerification,
	PhoneVerification,
	EmailExists
}

export function SignupPage() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const {findTenantBySignupDomain, verifyEmail, addSignupVerification, verifySignUpCode} = usePublicApi();
	const {signup} = useSignUpApi();

	const {result: tenant} = useAsync(async () => findTenantBySignupDomain(getSignupDomain()), []);

	const [state, setState] = useState(SignUpState.Form);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error>();

	const defaultBorrower: SignUpRequest = {
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		address: {addressLine1: ""},
		password: ""
	};

	// Form
	const [firstName, setFirstName] = useState<string>(defaultBorrower.firstName);
	const [lastName, setLastName] = useState<string>(defaultBorrower.lastName);
	const [email, setEmail] = useState<string>(defaultBorrower.email);

	const [prefix, setPrefix] = useState<string>("43");
	const [number, setNumber] = useState("");

	const [addressLine1, setAddressLine1] = useState<string>(defaultBorrower.address.addressLine1 ?? "");
	const [addressZipcode, setAddressZipcode] = useState<string>(defaultBorrower.address.zipcode ?? "");
	const [addressCity, setAddressCity] = useState<string>(defaultBorrower.address.city ?? "");
	const [addressCountry, setAddressCountry] = useState<string | null>(defaultBorrower.address.country ?? "");
	const [password, setPassword] = useState<string>("");
	const [repeatPassword, setRepeatPassword] = useState<string>("");

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [weakPassword, setWeakPassword] = useState(false);
	const [passwordMismatch, setPasswordMismatch] = useState(false);

	const [verificationCode, setVerificationCode] = useState<string>("");
	const [verificationValue, setVerificationValue] = useState<string>("");
	const [verificationError, setVerificationError] = useState(false);
	const [emailVerified, setEmailVerified] = useState(false);
	const [phoneVerified, setPhoneVerified] = useState(false);

	useEffect(() => {
		ipInfo().then((ipInfo) => {
			if (ipInfo.country) {
				setAddressCountry(ipInfo.country);
				const resolvedCountry = findCountry(ipInfo.country);
				if (resolvedCountry?.phone !== undefined) {
					setPrefix(resolvedCountry.phone);
				}
			}
			if (ipInfo.city) {
				setAddressCity(ipInfo.city);
			}
			if (ipInfo.postal) {
				setAddressZipcode(ipInfo.postal);
			}
		});
	}, []);

	function handleNumberChange(value: string) {
		// - digits only, eg: 07321234578
		// - max. length = 24 digits
		// - no spaces allowed.
		if (value.length < 24 && value.match("^[0-9]*$")) {
			setNumber(value);
		}
	}

	function iStrongPassword(value: string) {
		// - min length of 8 letters
		// - at least 1 uppercase character
		// - at least 1 special character (!@#$%^&*)
		// - at least 1 digit
		const test = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g;
		return test.test(value);
	}

	function isPasswordRepeated(value: string) {
		return password === value;
	}

	async function handleVerification() {
		setVerificationError(false);
		let result: boolean = false;

		let validEmail = emailVerified;
		let validPhone = phoneVerified;
		try {
			if (state === SignUpState.EmailVerification) {
				const {valid} = await verifySignUpCode({
					type: "Email",
					code: `${verificationCode}`.trim(),
					value: `${verificationValue}`.trim()
				});
				if (valid) {
					setEmailVerified(true);
					validEmail = true;
					result = true;
				}
			} else if (state === SignUpState.PhoneVerification) {
				const {valid} = await verifySignUpCode({
					type: "Phone",
					code: `${verificationCode}`.trim(),
					value: `${verificationValue}`.trim()
				});
				if (valid) {
					setPhoneVerified(true);
					validPhone = true;
					result = true;
				}
			}
		} catch (err) {
			console.error("Failed to verify data", err);
		}

		if (!result) {
			setVerificationError(true);
			return;
		}

		await handleSignUp(validEmail, validPhone).catch(setError);
	}

	const normalizePhone = useCallback(() => {
		if (prefix.length > 0 && number.length > 0) {
			let n = number;
			if (n.startsWith("0")) n = n.substring(1);
			return `+${prefix}${n}`;
		}
		return "";
	}, [prefix, number]);

	async function handleSignUp(validEmail: boolean | undefined = false, validPhone: boolean | undefined = false) {
		setWeakPassword(false);
		setPasswordMismatch(false);

		if (!iStrongPassword(password)) {
			setWeakPassword(true);
			return;
		}
		if (!isPasswordRepeated(repeatPassword)) {
			setPasswordMismatch(true);
			return;
		}

		const data: SignUpRequest = {
			firstName,
			lastName,
			email,
			phone: normalizePhone(),
			address: {
				addressLine1,
				zipcode: addressZipcode,
				city: addressCity,
				country: addressCountry || "AT"
			},
			password,
			signUpDomain: getSignupDomain()
		};

		if (tenant !== undefined) {
			setError(undefined);
			setLoading(true);
			setVerificationCode("");

			try {
				// check if the user was already registered to the tenant
				const {existed} = await verifyEmail(tenant.centerId, data.email);
				if (existed) {
					setState(SignUpState.EmailExists);
					return;
				}

				// verify email on tenant configuration
				if (!validEmail && tenant.requiredEmailVerificationForSignUp) {
					setState(SignUpState.EmailVerification);
					await addSignupVerification(tenant.centerId, {email: data.email});
					setVerificationValue(data.email);
					return;
				}

				// verify phone on tenant configuration
				if (!validPhone && tenant.requiredPhoneVerificationForSignUp) {
					setState(SignUpState.PhoneVerification);
					await addSignupVerification(tenant.centerId, {phone: data.phone});
					setVerificationValue(data.phone);
					return;
				}

				await signup(data);
				navigate("/login");
			} catch (err) {
				setError(err as Error);
			} finally {
				setLoading(false);
			}
		}
	}

	function handleFormSubmit(ev: FormEvent) {
		ev.preventDefault();

		if (state === SignUpState.Form) {
			handleSignUp().catch(setError);
		} else {
			handleVerification().catch(setError);
		}
	}

	return (
		<MobileContainer showNav={false}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t("signup.form.title")}</title>
			</Helmet>
			<InfoCard>
				{state !== SignUpState.Form && state != SignUpState.EmailExists && (
					<Box p={2} component="form" onSubmit={handleFormSubmit}>
						<Grid container spacing={gs}>
							<Grid item xs={12}>
								<Typography variant="h3" startAdornment={<PersonRounded />}>
									{state === SignUpState.EmailVerification && t("signup.form.emailVerification")}
									{state === SignUpState.PhoneVerification && t("signup.form.phoneVerification")}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider sx={{mx: -gs}} />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1">
									{state === SignUpState.EmailVerification && t("signup.form.emailVerificationInfo")}
									{state === SignUpState.PhoneVerification && t("signup.form.phoneVerificationInfo")}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Verification code"
									value={verificationCode}
									onChange={setVerificationCode}
									required={true}
								/>
							</Grid>
							{error && (
								<Grid item xs={12}>
									<Alert severity="error">{error.message ?? "Error"}</Alert>
								</Grid>
							)}
							{verificationError && (
								<Grid item xs={12}>
									<Alert severity="error">Invalid code.</Alert>
								</Grid>
							)}
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									size="large"
									fullWidth
									type="submit"
									disabled={loading}
								>
									Verify
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="secondary"
									size="large"
									fullWidth
									onClick={() => navigate("/landing")}
								>
									Abbrechen
								</Button>
							</Grid>
						</Grid>
					</Box>
				)}

				{state === SignUpState.Form && (
					<Box p={2} component="form" onSubmit={handleFormSubmit}>
						<Grid container spacing={gs}>
							<Grid item xs={12}>
								<Typography variant="h3" startAdornment={<PersonRounded />}>
									Registrieren
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider sx={{mx: -gs}} />
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label={t("common.firstname")}
									name="firstName"
									value={firstName}
									onChange={setFirstName}
									required={tenant?.requiredNameForSignUp === true}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label={t("common.lastname")}
									name="lastName"
									value={lastName}
									onChange={setLastName}
									required={tenant?.requiredNameForSignUp === true}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={5.5}>
										<PhonePrefixSelect
											label=""
											value={prefix}
											onChange={(v) => setPrefix(v ?? "43")}
										/>
									</Grid>
									<Grid item xs={6.5}>
										<TextField
											fullWidth
											label={t("common.phone")}
											inputMode="tel"
											value={number}
											onChange={handleNumberChange}
											required={tenant?.requiredPhoneForSignUp === true}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label={t("common.email")}
									type="email"
									inputMode="email"
									name="email"
									value={email}
									onChange={setEmail}
									required={true}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label={t("common.street")}
									name="addressLine1"
									value={addressLine1}
									onChange={setAddressLine1}
									required={tenant?.requiredAddressForSignUp === true}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									fullWidth
									label={t("common.zip")}
									name="addressZipcode"
									value={addressZipcode}
									onChange={setAddressZipcode}
									required={tenant?.requiredAddressForSignUp === true}
								/>
							</Grid>
							<Grid item xs={8}>
								<TextField
									fullWidth
									label={t("common.city")}
									name="addressCity"
									value={addressCity}
									onChange={setAddressCity}
									required={tenant?.requiredAddressForSignUp === true}
								/>
							</Grid>
							<Grid item xs={12}>
								<CountrySelect
									label={t("common.country")}
									value={addressCountry}
									onChange={setAddressCountry}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									type={showPassword ? "text" : "password"}
									label={t("common.password")}
									value={password}
									onChange={setPassword}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowPassword(!showPassword)}
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									type={showPassword ? "text" : "password"}
									label={t("common.passwordRepeat")}
									value={repeatPassword}
									onChange={setRepeatPassword}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowPassword(!showPassword)}
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>

							{(error || passwordMismatch || weakPassword) && (
								<Grid item xs={12}>
									<Alert severity="error">
										{weakPassword && (
											<Grid container spacing={gs}>
												<Grid item xs={12}>
													<Typography variant="h6">
														{t("signup.weakPassword.title")}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="body1">
														{t("signup.weakPassword.info")}
													</Typography>
													<Typography variant="body2">
														<ul>
															<li>{t("signup.weakPassword.tip1")}</li>
															<li>{t("signup.weakPassword.tip2")}</li>
															<li>{t("signup.weakPassword.tip3")}</li>
															<li>{t("signup.weakPassword.tip4")}</li>
														</ul>
													</Typography>
												</Grid>
											</Grid>
										)}
										{passwordMismatch && (
											<Grid container spacing={gs}>
												<Grid item xs={12}>
													<Typography variant="h6">
														{t("signup.passwordMismatch.title")}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="body1">
														{t("signup.passwordMismatch.info")}
													</Typography>
												</Grid>
											</Grid>
										)}
										{error && (
											<Grid container spacing={gs}>
												<Grid item xs={12}>
													<Typography variant="h6">{t("signup.signupError")}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="body1">{error.message}</Typography>
												</Grid>
											</Grid>
										)}
									</Alert>
								</Grid>
							)}

							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									size="large"
									fullWidth
									type="submit"
									disabled={loading}
								>
									{t("signup.form.title")}
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="secondary"
									size="large"
									fullWidth
									onClick={() => navigate("/landing")}
									disabled={loading}
								>
									{t("cancel")}
								</Button>
							</Grid>
						</Grid>
					</Box>
				)}

				{state === SignUpState.EmailExists && (
					<Box p={2}>
						<Grid container spacing={gs}>
							<Grid item xs={12}>
								<Typography variant="h2" align="center">
									{t("signup.emailExists.title")}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1">{t("signup.emailExists.info", {email})}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2">{t("signup.emailExists.tip")}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									size="large"
									fullWidth
									onClick={() => navigate("/login")}
								>
									{t("login")}
								</Button>
							</Grid>
						</Grid>
					</Box>
				)}
				{tenant && (
					<Box p={2}>
						<Grid container spacing={gs}>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography align="right">
									<Link
										href={`https://variocube-docs.s3.eu-west-1.amazonaws.com/variocube.lend/${tenant.centerId}/imprint.pdf`}
										underline="always"
										target="imprint"
									>
										{t("imprint.title")}
									</Link>
								</Typography>
							</Grid>
						</Grid>
					</Box>
				)}
			</InfoCard>
		</MobileContainer>
	);
}
