import {Box, Grid, List, ListItemButton, ListItemText, Typography} from "@mui/material";
import {createElement, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {gs} from "../../consts";
import {useAuthContext} from "../../context/AuthContextProvider";
import {Tenant, TenantsProvider} from "../../domain/tenants";
import {FullScreenCard} from "../../layout/FullScreenCard";
import {Loading} from "../../layout/Loading";
import {MobileContainer} from "../../layout/MobileContainer";

export function TenantSelect() {
	const {api} = useAuthContext();
	const navigate = useNavigate();
	const [tenants, setTenants] = useState<Tenant[]>();
	useEffect(() => {
		if (api && api.auth) {
			const tenantsProvider = new TenantsProvider(api);
			tenantsProvider.list().then((tenants) => {
				if (tenants.length == 1) {
					navigate(`/tenants/${tenants[0].centerId}`);
				}
				for (let tenant of tenants) {
					if (tenant.signUpDomains.includes(window.location.hostname)) {
						navigate(`/tenants/${tenant.centerId}`);
					}
				}
				setTenants(tenants);
			});
		}
	}, [api]);

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard actions={null}>
				<Box p={2}>
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<Typography variant="h1">Select Tenant</Typography>
						</Grid>
					</Grid>
				</Box>
				{!tenants && (
					<Box p={3}>
						<Loading />
					</Box>
				)}
				{tenants && (
					<List>
						{tenants.map((tenant) => (
							<ListItemButton key={tenant.centerId} component={Link} to={`/tenants/${tenant.centerId}`}>
								<ListItemText primary={tenant.name || tenant.centerId} secondary={tenant.centerId} />
							</ListItemButton>
						))}
					</List>
				)}
			</FullScreenCard>
		</MobileContainer>
	);
}
