import {Box, Grid, Icon, List, ListItem, ListItemText, Typography} from "@mui/material";
import {SearchIcon, TextField} from "@variocube/app-ui";
import * as React from "react";
import {createElement, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {gs} from "../../../consts";
import {useAuthContext} from "../../../context/AuthContextProvider";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {AddressDisplay} from "../../../displays/AddressDisplay";
import {Cube, CubesProvider} from "../../../domain/cubes";
import {Page} from "../../../domain/types";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {Loading} from "../../../layout/Loading";
import {MobileContainer} from "../../../layout/MobileContainer";

interface SiteSelectProps {}

export function SiteSelect(props: SiteSelectProps) {
	const {api} = useAuthContext();
	const navigate = useNavigate();
	const {tenantId} = useTenantContext();

	const [cubes, setCubes] = useState<Page<Cube>>();
	const [needle, setNeedle] = useState<string>("");

	useEffect(() => {
		if (tenantId && api && api.auth) {
			const cubesProvider = new CubesProvider(api);
			cubesProvider.list(tenantId).then((cubes) => {
				if (cubes.totalElements == 1) {
					navigate(`/tenants/${tenantId}/sites/${cubes.content[0].cubeId}`);
				}
				setCubes(cubes);
			});
		}
	}, [api, navigate, tenantId]);

	const filtered = useMemo<Cube[]>(() => {
		if (!cubes) {
			return [];
		}
		if (!needle) {
			return cubes.content;
		}
		return cubes.content.filter((cube) => {
			if (cube.description) {
				if (cube.description.toLowerCase().includes(needle.toLowerCase())) {
					return true;
				}
			}
			if (cube.address) {
				const fullAddress = Object.values(cube.address).join(" ");
				return fullAddress.toLowerCase().includes(needle.toLowerCase());
			}
			return false;
		});
	}, [cubes, needle]);

	return (
		<MobileContainer showNav={true}>
			<FullScreenCard actions={null}>
				<Box p={2}>
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<Typography variant="h1">Select Site</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								fullWidth
								label="Standortsuche"
								value={needle}
								onChange={setNeedle}
								InputProps={{
									startAdornment: (
										<Icon>
											<SearchIcon />
										</Icon>
									)
								}}
							/>
						</Grid>
					</Grid>
				</Box>
				{!cubes && (
					<Box p={3}>
						<Loading />
					</Box>
				)}
				{cubes && (
					<List>
						{filtered.length == 0 && (
							<ListItem>
								<ListItemText primary="Keine Standorte gefunden" />
							</ListItem>
						)}
						{filtered.map((cube) => (
							<ListItem
								key={cube.cubeId}
								onClick={() => navigate(`/tenants/${tenantId}/sites/${cube.cubeId}`)}
								button
							>
								<ListItemText
									primary={cube.description || cube.cubeId}
									secondary={
										cube.address ? <AddressDisplay address={cube.address} singleLine /> : undefined
									}
								/>
							</ListItem>
						))}
					</List>
				)}
			</FullScreenCard>
		</MobileContainer>
	);
}
