import {ThumbUp} from "@mui/icons-material";
import {Box, Button, Grid, Typography} from "@mui/material";
import {createElement, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {gs} from "../../../../consts";
import {useAuthContext} from "../../../../context/AuthContextProvider";
import {useTenantContext} from "../../../../context/TenantContextProvider";
import {LargeIconDisplay} from "../../../../displays/LargeIconDisplay";
import {Item, ItemsProvider} from "../../../../domain/items";
import {Rental, RentalsProvider, RentalWrapper} from "../../../../domain/rentals";
import {Ticket} from "../../../../domain/tickets";
import {useLocalization} from "../../../../i18n";
import {FullScreenCard} from "../../../../layout/FullScreenCard";
import {MobileContainer} from "../../../../layout/MobileContainer";
import {EndRentalBoxOpenScreen} from "../EndRentalBoxOpenScreen";

interface TicketEndRentalProps {
	rental: Rental;
	ticket: Ticket;
}

export function TicketEndRental(props: TicketEndRentalProps) {
	const {rental, ticket} = props;
	const {api} = useAuthContext();
	const {tenantId} = useTenantContext();
	const {t} = useLocalization();
	const navigate = useNavigate();

	const [item, setItem] = useState<Item>();
	const [boxIsOpen, setBoxIsOpen] = useState<boolean>(false);
	const [instructionsRead, setInstructionsRead] = useState<boolean>(false);

	useEffect(() => {
		if (api && api.auth && tenantId && rental) {
			const itemsProvider = new ItemsProvider(api);
			itemsProvider.get(tenantId, rental.itemUuid).then(setItem);

			const wrapper = new RentalWrapper(rental);
			if (wrapper.isActive()) {
				const rentalsProvider = new RentalsProvider(api);
				rentalsProvider.openRentalBox(tenantId, rental.uuid, true).then(() => {
					setBoxIsOpen(true);
					setInstructionsRead(false);
				});
			} else {
				setBoxIsOpen(true);
				setInstructionsRead(true);
			}
		}
	}, [rental, api, tenantId]);

	if (!boxIsOpen) {
		return null;
	}

	if (item && boxIsOpen && !instructionsRead) {
		return <EndRentalBoxOpenScreen item={item} rental={rental} onNext={() => setInstructionsRead(true)} />;
	}

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard
				actions={
					<Grid container spacing={gs}>
						{item && item.stored && new RentalWrapper(rental).isActive() && (
							<Grid item xs={12}>
								<Typography variant="h3" align="center">
									{t("ticket.thanks.close", {boxNUmber: `${item.stored.boxNumber}`})}
								</Typography>
							</Grid>
						)}
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								onClick={() => navigate(`/tenants/${tenantId}`)}
							>
								{t("continue")}
							</Button>
						</Grid>
					</Grid>
				}
			>
				<Box p={2}>
					<LargeIconDisplay
						title={t("ticket.thanks.title")}
						info={t("ticket.thanks.info")}
						icon={
							<ThumbUp
								color="secondary"
								sx={{
									width: "5em",
									height: "5em"
								}}
							/>
						}
					/>
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}
