import {Help, SentimentDissatisfied} from "@mui/icons-material";
import {Box, Button, Grid, RadioGroup, Typography} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import {createElement, useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {gs} from "../../../../consts";
import {useAuthContext} from "../../../../context/AuthContextProvider";
import {useTenantContext} from "../../../../context/TenantContextProvider";
import {LargeIconDisplay} from "../../../../displays/LargeIconDisplay";
import {Rental, RentalsProvider, RentalWrapper} from "../../../../domain/rentals";
import {CreateTicketRequest, Ticket, TicketsProvider, TicketType} from "../../../../domain/tickets";
import {useLocalization} from "../../../../i18n";
import {SimpleRadio} from "../../../../inputs/SimpleRadio";
import {FullScreenCard} from "../../../../layout/FullScreenCard";
import {HeaderWithIcon} from "../../../../layout/HeaderWithIcon";
import {MobileContainer} from "../../../../layout/MobileContainer";
import {TicketContinueRental} from "./TicketContinueRental";
import {TicketEndRental} from "./TicketEndRental";
import {TicketReplaceItem} from "./TicketReplaceItem";

enum RentalTicketOutcome {
	End = "End",
	Replace = "Replace",
	Continue = "Continue"
}

export function TicketPage() {
	const {t} = useLocalization();
	const {api} = useAuthContext();
	const {tenantId} = useTenantContext();
	const routeParams = useParams();
	const rentalUuid = routeParams.rentalUuid;
	const navigate = useNavigate();

	const [rental, setRental] = useState<Rental>();
	const [ticketType, setTicketType] = useState<TicketType>();
	const [comment, setComment] = useState<string>("");
	const [commentFocused, setCommentFocused] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [ticket, setTicket] = useState<Ticket>();
	const [ticketOutcome, setTicketOutcome] = useState<RentalTicketOutcome>();

	useEffect(() => {
		if (api && api.auth && tenantId && rentalUuid) {
			const rentalsProvider = new RentalsProvider(api);
			rentalsProvider.get(tenantId, rentalUuid).then(setRental);
		}
	}, [api, tenantId, rentalUuid]);

	const handleSubmitTicket = useCallback(() => {
		if (api && api.auth && tenantId && rental && ticketType && comment) {
			setSubmitting(true);
			const ticketsProvider = new TicketsProvider(api);
			const createTicketRequest: CreateTicketRequest = {
				rentalUuid: rental.uuid,
				itemUuid: rental.itemUuid,
				type: ticketType,
				comment: comment
			};
			ticketsProvider.createTicket(tenantId, createTicketRequest).then((ticket) => {
				const wrapper = new RentalWrapper(rental);
				if (!wrapper.isActive()) {
					setTicketOutcome(RentalTicketOutcome.End);
				}
				setTicket(ticket);
				setSubmitting(false);
			});
		}
	}, [api, tenantId, rental, ticketType, comment]);

	const canSubmit = useMemo<boolean>(
		() => Boolean(rental && ticketType && comment.length > 10 && !submitting),
		[rental, ticketType, comment, submitting]
	);

	if (rental && ticket) {
		if (ticketOutcome) {
			switch (ticketOutcome) {
				case RentalTicketOutcome.End:
					return <TicketEndRental rental={rental} ticket={ticket} />;
				case RentalTicketOutcome.Replace:
					return <TicketReplaceItem rental={rental} ticket={ticket} />;
				case RentalTicketOutcome.Continue:
					return <TicketContinueRental rental={rental} ticket={ticket} />;
			}
		}

		return (
			<MobileContainer showNav={false}>
				<FullScreenCard
					actions={
						<Grid container spacing={gs}>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									onClick={() => setTicketOutcome(RentalTicketOutcome.End)}
								>
									{t("ticket.howToContinue.endRental")}
								</Button>
							</Grid>
							{/*
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									onClick={() => setTicketOutcome(RentalTicketOutcome.Replace)}
								>
									{t("ticket.howToContinue.replaceItem")}
								</Button>
							</Grid>
							*/}
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									onClick={() => setTicketOutcome(RentalTicketOutcome.Continue)}
								>
									{t("ticket.howToContinue.continueRental")}
								</Button>
							</Grid>
						</Grid>
					}
				>
					<LargeIconDisplay
						icon={
							<Help
								color="secondary"
								sx={{
									width: "5em",
									height: "5em"
								}}
							/>
						}
						title={t("ticket.howToContinue.title")}
						size={5}
					/>
				</FullScreenCard>
			</MobileContainer>
		);
	}

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard
				actions={<ReportProblemActions onNext={handleSubmitTicket} disabled={!canSubmit} />}
				onBack={() => navigate(`/tenants/${tenantId}/rentals/${rentalUuid}`)}
				paddingTop="3em"
			>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						{commentFocused ? (
							<Box p={2}>
								<HeaderWithIcon
									title={t("ticket.ohNoes")}
									icon={<SentimentDissatisfied color="secondary" />}
								/>
							</Box>
						) : (
							<LargeIconDisplay
								icon={
									<SentimentDissatisfied
										color="secondary"
										sx={{
											width: "5em",
											height: "5em"
										}}
									/>
								}
								title={t("ticket.ohNoes")}
								size={5}
							/>
						)}
					</Grid>
					<Grid item xs={12}>
						<Box p={2}>
							<Grid container spacing={gs}>
								<Grid item xs={12}>
									<RadioGroup>
										<SimpleRadio
											label={t("ticket.Defective")}
											selected={ticketType == TicketType.Defective}
											onChange={() => setTicketType(TicketType.Defective)}
										/>
										<SimpleRadio
											label={t("ticket.Lost")}
											selected={ticketType == TicketType.Lost}
											onChange={() => setTicketType(TicketType.Lost)}
										/>
										<SimpleRadio
											label={t("ticket.Maintenance")}
											selected={ticketType == TicketType.Maintenance}
											onChange={() => setTicketType(TicketType.Maintenance)}
										/>
									</RadioGroup>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label={t("ticket.comment")}
										fullWidth
										multiline
										rows={commentFocused ? 5 : 2}
										value={comment}
										onChange={setComment}
										onFocus={() => setCommentFocused(true)}
										onBlur={() => setCommentFocused(false)}
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</FullScreenCard>
		</MobileContainer>
	);
}

interface ReportProblemActionsProps {
	onNext: () => void;
	disabled: boolean;
}

function ReportProblemActions(props: ReportProblemActionsProps) {
	const {onNext, disabled} = props;
	const {t} = useLocalization();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="body2">{t("ticket.createInfo")}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Button variant="contained" color="primary" fullWidth onClick={onNext} disabled={disabled}>
					{t("next")}
				</Button>
			</Grid>
		</Grid>
	);
}
