import * as React from "react";
import {useLocalization} from "../i18n";
import {createElement, useCallback, useMemo} from "react";
import {TextField} from "@variocube/app-ui";
import {InputAdornment, MenuItem} from "@mui/material";

interface DurationInputProps {
	duration: number;
	onChange: (value: number) => void;
	maxFutureMinutes?: number;
}

export function DurationInput(props: DurationInputProps) {
	const {duration, onChange, maxFutureMinutes = 12 * 60} = props;
	const {t} = useLocalization();

	const handleChange = useCallback(
		(value: string) => {
			onChange(parseInt(value));
		},
		[onChange]
	);

	const formattedDuration = useMemo<string>(() => `${duration}`, [duration]);

	const options = useMemo<number[]>(() => {
		const options = [];
		let duration = 30;
		while (duration <= maxFutureMinutes) {
			options.push(duration);
			duration += 30;
		}
		return options;
	}, [maxFutureMinutes]);

	return (
		<TextField
			value={formattedDuration}
			onChange={handleChange}
			select
			fullWidth
			InputProps={{
				startAdornment: <InputAdornment position="start">{t("durationInput.duration")}</InputAdornment>
			}}
		>
			{options.map((option) => (
				<MenuItem key={`${option}`} value={`${option}`}>
					{`${option} ${t("durationInput.minutes")}`}
				</MenuItem>
			))}
		</TextField>
	);
}
