import {PersonRounded} from "@mui/icons-material";
import {Box, Button, Divider, Grid, Link, Typography} from "@mui/material";
import {ConfirmDialog} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {gs} from "../../../consts";
import {useAuthContext} from "../../../context/AuthContextProvider";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {AddressDisplay} from "../../../displays/AddressDisplay";
import {Rental, RentalsProvider} from "../../../domain/rentals";
import {Page, User} from "../../../domain/types";
import {UsersProvider} from "../../../domain/users";
import {useLocalization} from "../../../i18n";
import {HeaderWithIcon} from "../../../layout/HeaderWithIcon";
import {InfoCard} from "../../../layout/InfoCard";
import {Loading} from "../../../layout/Loading";
import {MobileContainer} from "../../../layout/MobileContainer";
import {ShortenedDisplay} from "../../../layout/ShortenedDisplay";

export function ProfilePage() {
	const {api} = useAuthContext();
	const navigate = useNavigate();
	const {tenantId} = useTenantContext();
	const {t} = useLocalization();

	const [user, setUser] = useState<User>();
	const [rentals, setRentals] = useState<Page<Rental>>();
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const usersProvider = new UsersProvider(api);

	useEffect(() => {
		if (api && api.auth && tenantId) {
			const usersProvider = new UsersProvider(api);
			usersProvider.currentUser(tenantId).then(setUser);
			const rentalsProvider = new RentalsProvider(api);
			rentalsProvider.list(tenantId).then(setRentals);
		}
	}, [api, tenantId]);

	const showMore = useCallback(() => {
		navigate(`/tenants/${tenantId}/rentals`);
	}, [tenantId, navigate]);

	const deleteAccount = () => {
		if (!user) console.debug("Cannot delete account of user when user is not set");
		return usersProvider.deleteAccount(tenantId, "not specified").then(() => {
			setConfirmDeleteOpen(false);
			setTimeout(() => navigate(`/logout`), 3000);
		});
	};

	return (
		<MobileContainer showNav={true}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t("profile.title")}</title>
			</Helmet>
			<InfoCard>
				<Box p={2}>
					<Grid container spacing={gs}>
						{!user && (
							<Grid item xs={12}>
								<Loading />
							</Grid>
						)}
						{user && (
							<Fragment>
								<Grid item xs={12}>
									<HeaderWithIcon
										title={t("profile.title")}
										icon={<PersonRounded color="secondary" />}
										variant="h3"
									/>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Box paddingLeft={5} paddingRight={5}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Typography variant="body1">
													{user.firstName} {user.lastName}
												</Typography>
											</Grid>
											{user.address && (
												<Grid item xs={12}>
													<AddressDisplay address={user.address} />
												</Grid>
											)}
											{user.phone && (
												<Grid item xs={12}>
													<Typography variant="body1">Tel.: {user.phone}</Typography>
												</Grid>
											)}
											{user.email && (
												<Grid item xs={12}>
													<Typography variant="body1">
														E-Mail:{" "}
														<Link href={`mailto:${user.email}`}>
															<ShortenedDisplay value={user.email} maxLen={30} />
														</Link>
													</Typography>
												</Grid>
											)}
											<Grid item xs={12}>
												<Box p={3}>
													<Typography align="center">
														<Button
															variant="contained"
															color="primary"
															onClick={() => navigate("/logout")}
														>
															Logout
														</Button>
													</Typography>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Box paddingLeft={5} paddingRight={5}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Typography align="center">
													<Button
														variant="contained"
														color="secondary"
														onClick={() => setConfirmDeleteOpen(true)}
													>
														{t("profile.delete.button")}
													</Button>

													<ConfirmDialog
														open={confirmDeleteOpen}
														title={t("profile.delete.prompt")}
														confirmTitle={t("continue")}
														cancel={t("cancel")}
														onClose={() => {
															setConfirmDeleteOpen(false);
														}}
														onConfirm={deleteAccount}
													/>
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Box p={3} />
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Fragment>
						)}
						{user && !rentals && (
							<Grid item xs={12}>
								<Loading />
							</Grid>
						)}
						{rentals && rentals.totalElements > 0 && (
							<Fragment>
								<Grid item xs={12}>
									<HeaderWithIcon
										title={t("profile.history")}
										icon={<PersonRounded color="secondary" />}
										variant="h3"
									/>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Box paddingLeft={5}>
										<Grid container spacing={1}>
											{rentals.content.map((r) => (
												<Fragment key={r.uuid}>
													<Grid item xs={4}>
														{r.created.toString().substring(0, 10)}
													</Grid>
													<Grid item xs={8}>
														<ShortenedDisplay value={r.itemName} maxLen={25} />
													</Grid>
												</Fragment>
											))}
										</Grid>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box p={3}>
										<Typography align="center">
											<Button variant="contained" color="primary" onClick={() => showMore()}>
												{t("rentals.showMore")}
											</Button>
										</Typography>
									</Box>
								</Grid>
							</Fragment>
						)}
					</Grid>
				</Box>
			</InfoCard>
		</MobileContainer>
	);
}
