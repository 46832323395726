import {Box, Button, Grid, Typography, useTheme} from "@mui/material";
import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import hourGlassRegular from "../../../assets/icons/hourglass-half-regular.svg";
import {gs} from "../../../consts";
import {useAuthContext} from "../../../context/AuthContextProvider";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {LargeIconDisplay} from "../../../displays/LargeIconDisplay";
import {Rental, RentalsProvider} from "../../../domain/rentals";
import {useLocalization} from "../../../i18n";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {MobileContainer} from "../../../layout/MobileContainer";

interface GracePeriodScreenProps {
	rental: Rental;
	seconds: number;
	onEndGracePeriod: () => void;
}

export function GracePeriodScreen(props: GracePeriodScreenProps) {
	const {rental, seconds, onEndGracePeriod} = props;
	const {api} = useAuthContext();
	const {tenantId} = useTenantContext();
	const {t} = useLocalization();

	const [countDown, setCountDown] = useState(seconds);

	useEffect(() => {
		const interval = setInterval(() => {
			if (countDown > 0) {
				setCountDown(countDown - 1);
			} else {
				onEndGracePeriod();
			}
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [seconds, countDown, onEndGracePeriod]);

	const handleOpenAgain = useCallback(() => {
		if (api && api.auth && tenantId) {
			const rentalsProvider = new RentalsProvider(api);
			rentalsProvider.openRentalBox(tenantId, rental.uuid, false);
		}
	}, [api, tenantId, rental]);

	const theme = useTheme();
	return (
		<MobileContainer showNav={false}>
			<FullScreenCard
				actions={
					<Button variant="contained" color="primary" fullWidth onClick={handleOpenAgain}>
						{t("rentals.gracePeriod.openAgain")}
					</Button>
				}
			>
				<Box p={2}>
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<LargeIconDisplay
								src={hourGlassRegular}
								title={t("rentals.gracePeriod.title")}
								info={t("rentals.gracePeriod.info", {seconds})}
								size={5}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant="h1"
								align="center"
								sx={{fontWeight: "400", color: theme.palette.error.main}}
							>
								{countDown}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" align="center">
								{t("rentals.gracePeriod.secondsRemaining")}
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}
