import {Api} from "../libs/Api";
import {User} from "./types";

export class UsersProvider {
	readonly api: Api;

	constructor(api: Api) {
		this.api = api;
	}

	async currentUser(tenantId: string): Promise<User> {
		const url = `tenants/${tenantId}/users/current-user`;
		return this.api.get<User>(url);
	}

	async userEnsureTenantAssigned(tenantIdOrDomain: string): Promise<User> {
		const url = `tenants/${tenantIdOrDomain}/users/current-user-ensure-tenant-access`;
		return this.api.post<User>(url, {});
	}

	async deleteAccount(tenantId: string, reason: string): Promise<User> {
		const url = `tenants/${tenantId}/users/deleteme`;
		return this.api.delete<User>(url, {reason});
	}
}
