import {Grid, Typography} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";
import {OverridableStringUnion} from "@mui/types";
import {createElement, ReactElement} from "react";
import {gs} from "../consts";

interface HeaderWithIconProps {
	title: string;
	icon: ReactElement;
	variant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>;
}

export function HeaderWithIcon(props: HeaderWithIconProps) {
	const {title, icon, variant} = props;

	return (
		<Grid container spacing={gs} alignItems="center">
			<Grid item>
				{icon}
			</Grid>
			<Grid item>
				<Typography variant={variant}>{title}</Typography>
			</Grid>
		</Grid>
	);
}
