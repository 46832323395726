import {Api} from "../libs/Api";

export enum TicketType {
	Defective = "Defective",
	Lost = "Lost",
	Maintenance = "Maintenance"
}

export interface Ticket {}

export interface CreateTicketRequest {
	itemUuid: string;
	rentalUuid: string;
	comment: string;
	type: TicketType;
}

export class TicketsProvider {
	readonly api: Api;
	constructor(api: Api) {
		this.api = api;
	}

	createTicket(tenantId: string, request: CreateTicketRequest): Promise<Ticket> {
		return this.api.post(`/tenants/${tenantId}/tickets`, request);
	}
}
