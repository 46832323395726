import * as React from "react";
import {
	createContext,
	createElement,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import {RouteProps, useParams} from "react-router";
import {Cube, CubesProvider} from "../domain/cubes";
import {useAuthContext} from "./AuthContextProvider";
import {useTenantContext} from "./TenantContextProvider";

interface SiteContextProps extends PropsWithChildren<any> {
}

interface SiteContextData {
	cubeId: string;
	cube?: Cube;
	provideCube: (cube: Cube) => void;
}

const emptyContext: SiteContextData = {
	cubeId: "",
	provideCube: (cube: Cube) => {}
};

export const SiteContext = createContext<SiteContextData>(emptyContext);

export function SiteContextProvider(props: SiteContextProps) {
	const {children} = props;
	const routeParams = useParams();
	const {api} = useAuthContext();
	const {tenantId} = useTenantContext();

	const routeParamsCubeId = routeParams.cubeId;

	const [cubeId, setCubeId] = useState<string>("");
	const [cube, setCube] = useState<Cube | undefined>(undefined);

	useEffect(() => {
		if (tenantId && routeParamsCubeId) {
			setCubeId(routeParamsCubeId);
			if (api && api.auth) {
				const cubesProvider = new CubesProvider(api);
				cubesProvider.get(tenantId, routeParamsCubeId).then(setCube);
			}
		}
	}, [tenantId, routeParamsCubeId]);

	const provideCube = useCallback((cube: Cube) => {
		setCubeId(cube.cubeId);
		setCube(cube);
	}, [setCube, setCubeId]);

	const currentContext = useMemo<SiteContextData>(() => ({
		cubeId: cubeId,
		cube,
		provideCube
	}), [cubeId, cube, setCubeId]);

	return (
		<SiteContext.Provider value={currentContext}>{children}</SiteContext.Provider>
	);
}

export function useSiteContext() {
	return useContext(SiteContext);
}
