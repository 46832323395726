import {BottomNavigation, BottomNavigationAction, useMediaQuery, useTheme} from "@mui/material";
import {createElement, useMemo} from "react";
import {useLocalization} from "../i18n";
import {AddBox, ConfirmationNumber, Info, Person} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useTenantContext} from "../context/TenantContextProvider";

interface BottomNavProps {}

export function BottomNav(props: BottomNavProps) {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const {pathname} = useLocation();
	const {tenantId} = useTenantContext();

	const theme = useTheme();
	const isDesktop = useMediaQuery("(min-width:600px)");

	const value = useMemo<number>(() => {
		const patterns = [
			`/tenants/${tenantId}/profile`,
			`/tenants/${tenantId}/sites`,
			`/tenants/${tenantId}/rentals`,
			`/tenants/${tenantId}/imprint`
		];
		for (let i = 0; i < patterns.length; i++) {
			if (pathname.startsWith(patterns[i])) {
				return i;
			}
		}
		return 1;
	}, [pathname, tenantId]);

	if (!tenantId) {
		return null;
	}

	return (
		<BottomNavigation
			showLabels
			value={value}
			sx={{
				backgroundColor: theme.palette.secondary.main,
				position: !isDesktop ? "fixed" : undefined,
				bottom: !isDesktop ? 0 : undefined,
				width: "100%"
			}}
		>
			<BottomNavigationAction
				label={t("bottomNav.profile")}
				icon={<Person />}
				onClick={() => navigate(`/tenants/${tenantId}/profile`)}
				sx={{color: theme.palette.secondary.contrastText}}
			/>
			<BottomNavigationAction
				label={t("bottomNav.rent")}
				icon={<AddBox />}
				onClick={() => navigate(`/tenants/${tenantId}`)}
				sx={{color: theme.palette.secondary.contrastText}}
			/>
			<BottomNavigationAction
				label={t("bottomNav.rentals")}
				icon={<ConfirmationNumber />}
				onClick={() => navigate(`/tenants/${tenantId}/rentals`)}
				sx={{color: theme.palette.secondary.contrastText}}
			/>
			<BottomNavigationAction
				label={t("bottomNav.info")}
				icon={<Info />}
				onClick={() => navigate(`/tenants/${tenantId}/imprint`)}
				sx={{color: theme.palette.secondary.contrastText}}
			/>
		</BottomNavigation>
	);
}
