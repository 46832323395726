import * as React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import {createElement, ReactNode} from "react";

interface SimpleCheckboxProps {
	label: ReactNode;
	checked: boolean;
	onChange: (checked: boolean) => void;
}

export function SimpleCheckbox(props: SimpleCheckboxProps) {
	const {label, checked, onChange} = props;

	return (
		<FormControlLabel
			label={label}
			control={<Checkbox checked={checked} onChange={(event) => onChange(event.target.checked)} />}
		/>
	);
}
