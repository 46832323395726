import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import * as React from "react";
import {createElement, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import route from "../../../assets/icons/route-solid.svg";
import {gs} from "../../../consts";
import {useAuthContext} from "../../../context/AuthContextProvider";
import {useSiteContext} from "../../../context/SiteContextProvider";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {AddressDisplay} from "../../../displays/AddressDisplay";
import {SvgIconDisplay} from "../../../displays/SvgIconDisplay";
import {Cube, cubeImage, CubesProvider, CubeWrapper} from "../../../domain/cubes";
import {Page} from "../../../domain/types";
import {useLocalization} from "../../../i18n";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {Loading} from "../../../layout/Loading";
import {MobileContainer} from "../../../layout/MobileContainer";

interface SiteDetailsProps {}

export function SiteDetails(props: SiteDetailsProps) {
	const {t} = useLocalization();
	const {api} = useAuthContext();
	const navigate = useNavigate();
	const {tenantId} = useTenantContext();
	const {cubeId} = useSiteContext();
	const [cubes, setCubes] = useState<Page<Cube>>();
	const [cube, setCube] = useState<Cube>();

	useEffect(() => {
		if (tenantId && api && api.auth && cubeId) {
			const cubesProvider = new CubesProvider(api);
			cubesProvider.list(tenantId).then(setCubes);
			cubesProvider.get(tenantId, cubeId).then(setCube);
		}
	}, [api, navigate, tenantId, cubeId]);

	const allowBack = useMemo<boolean>(() => Boolean(cubes && cubes.totalElements > 1), [cubes]);
	const mapsUrl = useMemo<string | undefined>(() => (cube ? new CubeWrapper(cube).mapsUrl : undefined), [cube]);

	return (
		<MobileContainer showNav={true}>
			<FullScreenCard
				onBack={allowBack ? () => navigate(`/tenants/${tenantId}`) : undefined}
				imageUrl={cubeImage(tenantId, cubeId)}
				actions={
					<Box>
						<Typography align="center">
							<Button
								variant="contained"
								color="primary"
								onClick={() => navigate(`/tenants/${tenantId}/sites/${cubeId}/models`)}
							>
								{t("site.bookNow")}
							</Button>
						</Typography>
					</Box>
				}
			>
				<Box p={2}>
					<Grid container spacing={gs}>
						{!cube && (
							<Grid item xs={12}>
								<Loading />
							</Grid>
						)}
						{cube && (
							<>
								<Grid item xs={12}>
									<Typography variant="h2">{cube.description}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={gs}>
										<Grid item sx={{flexGrow: 1}}>
											{cube.address && <AddressDisplay address={cube.address} />}
										</Grid>
										<Grid item>
											{mapsUrl && (
												<Button
													variant="contained"
													color="primary"
													href={mapsUrl}
													target="googlemaps"
													endIcon={
														<SvgIconDisplay
															src={route}
															alt={t("site.route")}
															style={{width: "1em", height: "1em"}}
														/>
													}
												>
													{t("site.route")}
												</Button>
											)}
										</Grid>
									</Grid>
								</Grid>
								{cube.site && (
									<>
										<Grid item xs={12}>
											<Divider />
										</Grid>
										<Grid item xs={12}>
											{cube.site}
										</Grid>
									</>
								)}
							</>
						)}
					</Grid>
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}
