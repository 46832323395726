import * as React from "react";
import {createContext, createElement, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Api} from "../libs/Api";
import {AuthenticatedToken} from "../domain/auth";

export interface AuthContextData {
    auth: AuthenticatedToken;
    provideToken: (token: AuthenticatedToken) => void;
    logout: () => void;
    api: Api;
}

const emptyContext: AuthContextData = {
    auth: AuthenticatedToken.nonAuthenticated(),
    provideToken: (token: AuthenticatedToken) => {},
    logout: () => {},
    api: new Api("/")
};

export const AuthContext = createContext<AuthContextData>(emptyContext);

interface AuthContextProviderProps extends PropsWithChildren<{
    notAuthenticatedUrl: string;
}> {}

export function AuthContextProvider({notAuthenticatedUrl, children}: AuthContextProviderProps) {
    const [auth, setAuth] = useState<AuthenticatedToken>(AuthenticatedToken.fromSessionStorage() || emptyContext.auth);

    useEffect(() => {
        const token = AuthenticatedToken.fromSessionStorage();
        if(token && token.isLoggedIn) {
            setAuth(token);
        }
    }, []);

    const provideToken = useCallback((newAuth: AuthenticatedToken) => {
        if(!newAuth.isLoggedIn) {
            throw new Error("You cannot provide an invalid token");
        }
        setAuth(newAuth);
        newAuth.toSessionStorage();
    }, []);

    const logout = useCallback(() => {
        auth.clearSessionStorage();
        setAuth(emptyContext.auth);
    }, [auth]);

    const api = useMemo<Api>(() => new Api(notAuthenticatedUrl, auth), [auth, notAuthenticatedUrl]);

    const currentContext = useMemo<AuthContextData>(() => ({
        auth, provideToken, logout, api
    }), [auth, provideToken, logout, api]);

    return (
        <AuthContext.Provider value={currentContext}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuthContext(): AuthContextData {
    return useContext<AuthContextData>(AuthContext);
}
