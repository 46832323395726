import {Api} from "../libs/Api";
import {Page} from "./types";

export interface Model {
	uuid: string;
	name: string;
	description: string;
	price?: number;
	imageUrl?: string;
}

export class ModelWrapper {
	readonly model: Model;

	constructor(model: Model) {
		this.model = model;
	}

	get imageUrl(): string {
		return this.model.imageUrl || "https://loremflickr.com/640/360";
	}
}

export class ModelsProvider {
	readonly api: Api;

	constructor(api: Api) {
		this.api = api;
	}

	async list(tenantId: string, cubeId: string): Promise<Model[]> {
		const url = `/tenants/${tenantId}/models?availableAt=${cubeId}`;
		return this.api.get<Model[]>(url);
	}

	async get(tenantId: string, modelUuid: string): Promise<Model> {
		const url = `/tenants/${tenantId}/models/${modelUuid}`;
		return this.api.get<Model>(url);
	}
}
