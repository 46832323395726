import {Button, Grid, Typography} from "@mui/material";
import * as React from "react";
import {createElement} from "react";
import keypad from "../../../assets/keypad.png";
import {gs} from "../../../consts";
import {Rental} from "../../../domain/rentals";
import {useLocalization} from "../../../i18n";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {MobileContainer} from "../../../layout/MobileContainer";

interface OfflineOpenBoxScreenProps {
	rental: Rental;
	onContinue: () => void;
}

export function OfflineOpenBoxScreen(props: OfflineOpenBoxScreenProps) {
	const {rental, onContinue} = props;
	const {t} = useLocalization();

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard
				actions={
					<Button variant="contained" color="primary" fullWidth onClick={onContinue}>
						{t("continue")}
					</Button>
				}
				back
				onBack={onContinue}
			>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Typography align="center">
							<img src={keypad} style={{width: "55%"}} alt={t("rentals.offline.openBox.instructions")} />
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h3" align="center">
							{t("rentals.offline.openBox.title")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1" align="center">
							{t("rentals.offline.openBox.instructions")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h2" align="center">
							{rental.borrower ? rental.borrower.accessCode : "Not available"}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2" align="center">
							{t("rentals.offline.openBox.info")}
						</Typography>
					</Grid>
				</Grid>
			</FullScreenCard>
		</MobileContainer>
	);
}
