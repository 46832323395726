import * as React from "react";
import {createElement} from "react";
import {CircularProgress, Typography} from "@mui/material";

interface LoadingProps {}

export function Loading(props: LoadingProps) {
	return (
		<Typography align="center">
			<CircularProgress color="primary" size={32} sx={{mx: 0.25}} />
		</Typography>
	);
}
