import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import {TemporalRangeFormat} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {gs} from "../../../consts";
import {useAuthContext} from "../../../context/AuthContextProvider";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {CubesProvider} from "../../../domain/cubes";
import {Item, ItemsProvider, ItemWrapper} from "../../../domain/items";
import {RentalsProvider, RentalWrapper} from "../../../domain/rentals";
import {useLocalization} from "../../../i18n";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {Loading} from "../../../layout/Loading";
import {MobileContainer} from "../../../layout/MobileContainer";
import {ShortenedDisplay} from "../../../layout/ShortenedDisplay";
import {EndRentalBoxOpenScreen} from "./EndRentalBoxOpenScreen";
import {EndRentalInstructionsScreen} from "./EndRentalInstructionsScreen";
import {GracePeriodScreen} from "./GracePeriodScreen";
import {OfflineEndRentalScreen} from "./OfflineEndRentalScreen";
import {OfflineOpenBoxScreen} from "./OfflineOpenBoxScreen";
import {OpenBoxInstructionsScreen} from "./OpenBoxInstructionsScreen";
import {RemainingTime} from "./RemainingTime";
import {ThanksScreen} from "./ThanksScreen";

export function RentalDetailsView() {
	const {t} = useLocalization();
	const {api} = useAuthContext();
	const {tenantId} = useTenantContext();
	const routeParams = useParams();
	const rentalUuid = routeParams.rentalUuid;
	const navigate = useNavigate();

	const [rental, setRental] = useState<RentalWrapper>();
	const [item, setItem] = useState<Item>();
	const [openBox, setOpenBox] = useState<boolean>(false);
	const [endRental, setEndRental] = useState<boolean>(false);
	const [offlineOpen, setOfflineOpen] = useState<boolean>(false);
	const [boxIsOpen, setBoxIsOpen] = useState<boolean>(false);
	const [boxWasOpen, setBoxWasOpen] = useState<boolean>(false);
	const [gracePeriod, setGracePeriod] = useState<boolean>(false);

	useEffect(() => {
		if (api && api.auth && tenantId && rentalUuid) {
			const rentalsProvider = new RentalsProvider(api);
			const itemsProvider = new ItemsProvider(api);
			rentalsProvider.get(tenantId, rentalUuid).then((rental) => {
				setRental(new RentalWrapper(rental));
				itemsProvider.get(tenantId, rental.itemUuid).then(setItem);
			});
		}
	}, [api, tenantId, rentalUuid]);

	const {result: cube} = useAsync(async () => {
		if (rental !== undefined) {
			const itemProvider = new ItemsProvider(api);
			const item = await itemProvider.get(tenantId, rental.rental.itemUuid);
			if (!!item.stored?.cubeId) {
				const cubeProvider = new CubesProvider(api);
				return await cubeProvider.get(tenantId, item.stored.cubeId);
			}
		}
		return undefined;
	}, [api, rental]);

	const cubeConnected = useMemo<boolean>(() => (cube ? cube.connected : false), [cube]);

	const handleOpenBox = useCallback(() => {
		setBoxIsOpen(false);
		setBoxWasOpen(false);
		setGracePeriod(false);
		if (cubeConnected) {
			if (api && api.auth && tenantId && rental) {
				const rentalsProvider = new RentalsProvider(api);
				rentalsProvider.openRentalBox(tenantId, rental.rental.uuid, endRental).then(() => {
					if (endRental) {
						setBoxIsOpen(true);
						setBoxWasOpen(true);
						setGracePeriod(true);
					} else {
						setOpenBox(false);
					}
				});
			}
		} else {
			setOfflineOpen(true);
		}
	}, [api, tenantId, rental, endRental, cubeConnected]);

	const handleEndRental = useCallback(() => {
		if (api && api.auth && tenantId && rental) {
			const rentalsProvider = new RentalsProvider(api);
			rentalsProvider.endRental(tenantId, rental.rental.uuid).then((rental) => {
				setRental(new RentalWrapper(rental));
				setOpenBox(false);
				setEndRental(false);
				setBoxIsOpen(false);
				setOfflineOpen(false);
			});
		}
	}, [api, tenantId, rental]);

	const handleReset = useCallback(() => {
		if (api && api.auth && tenantId && rentalUuid) {
			const rentalsProvider = new RentalsProvider(api);
			rentalsProvider
				.get(tenantId, rentalUuid)
				.then((rental) => setRental(new RentalWrapper(rental)))
				.finally(() => {
					setOpenBox(false);
					setEndRental(false);
					setBoxIsOpen(false);
					setOfflineOpen(false);
				});
		}
	}, [api, tenantId, rentalUuid]);

	const canOpenBox = useMemo<boolean>(() => {
		if (!rental) {
			return false;
		}
		return rental.isActive();
	}, [rental]);

	const canKeep = useMemo<boolean>(() => {
		if (!rental) {
			return false;
		}
		return rental.canKeep();
	}, [rental]);

	const itemImage = useMemo<string | undefined>(() => {
		if (item) {
			return new ItemWrapper(item).imageUrl;
		}
		return undefined;
	}, [item]);

	const returnPath = useMemo<string>(() => {
		if (item && item.stored && item.stored.cubeId) {
			return `/tenants/${tenantId}/sites/${item.stored.cubeId}`;
		} else {
			return `/tenants/${tenantId}`;
		}
	}, [item]);

	if (item && rental && endRental && gracePeriod) {
		return <GracePeriodScreen rental={rental.rental} seconds={30} onEndGracePeriod={() => setGracePeriod(false)} />;
	}

	if (item && rental && endRental && boxWasOpen) {
		return <ThanksScreen onContinue={() => navigate(returnPath)} />;
	}

	if (item && rental && endRental && boxIsOpen) {
		return <EndRentalBoxOpenScreen item={item} rental={rental.rental} onNext={handleReset} allowTicket />;
	}

	if (item && rental && offlineOpen) {
		return <OfflineOpenBoxScreen rental={rental.rental} onContinue={handleReset} />;
	}

	if (item && rental && cube && endRental) {
		if (cubeConnected) {
			return (
				<EndRentalInstructionsScreen
					item={item}
					rental={rental.rental}
					cube={cube}
					onBack={() => setEndRental(false)}
					handleOpenBox={handleOpenBox}
				/>
			);
		} else {
			return <OfflineEndRentalScreen rental={rental.rental} onEndRental={() => handleEndRental()} />;
		}
	}

	if (item && rental && openBox) {
		return (
			<OpenBoxInstructionsScreen
				item={item}
				rental={rental.rental}
				allowKeep={canKeep}
				onOpenBox={() => handleOpenBox()}
				onEndRental={() => setEndRental(true)}
				onBack={() => setOpenBox(false)}
			/>
		);
	}

	return (
		<MobileContainer showNav={true}>
			<Helmet>
				<title>{rental ? rental.rental.itemName : "..."}</title>
			</Helmet>
			<FullScreenCard
				back
				onBack={() => navigate(`/tenants/${tenantId}/rentals`)}
				imageUrl={itemImage}
				actions={
					rental?.isActive() && item && item.stored ? (
						<Fragment>
							<Button
								variant="contained"
								color="primary"
								size="large"
								fullWidth
								onClick={() => setOpenBox(true)}
								disabled={!canOpenBox}
							>
								{t("actions.openbox")}
							</Button>
						</Fragment>
					) : null
				}
			>
				<Box p={2}>
					<Grid container spacing={gs}>
						{!rental && (
							<Grid item xs={12}>
								<Loading />
							</Grid>
						)}
						{rental && (
							<Fragment>
								<Grid item xs={6}>
									<Box paddingTop={0.5}>
										<Typography variant="h3">
											<ShortenedDisplay value={rental.rental.itemName} maxLen={25} />
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Typography align="right">
										<Button
											variant="outlined"
											color="error"
											onClick={() =>
												navigate(`/tenants/${tenantId}/rentals/${rental.rental.uuid}/ticket`)
											}
										>
											{t("rent.reportProblem")}
										</Button>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								{rental.isActive() && (
									<Grid item xs={12} style={{fontSize: "x-large", textAlign: "center"}}>
										<Box>{t("rent.remainingTime")}</Box>
										<Box>
											<RemainingTime rental={rental.rental} />
										</Box>
									</Grid>
								)}
								{!rental.isActive() && (
									<Grid item xs={12}>
										<TemporalRangeFormat from={rental.rental.from} until={rental.rental.until} />
									</Grid>
								)}

								<Grid item xs={12}>
									<Divider />
								</Grid>
								{!item && (
									<Grid item xs={12}>
										<Loading />
									</Grid>
								)}
								{item && (
									<Grid item xs={12}>
										<Box
											sx={{
												maxHeight: "8vh",
												overflow: "hidden",
												maskImage: "linear-gradient(to bottom, black 50%, transparent 100%)",
												cursor: "pointer"
											}}
										>
											<Typography variant="body1" sx={{whiteSpace: "pre-wrap"}}>
												{item.description}
											</Typography>
										</Box>
									</Grid>
								)}
							</Fragment>
						)}
					</Grid>
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}
