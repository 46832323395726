import * as React from "react";
import {Rental} from "../../../../domain/rentals";
import {Ticket} from "../../../../domain/tickets";
import {createElement} from "react";

interface TicketReplaceItemProps {
	rental: Rental;
	ticket: Ticket;
}

export function TicketReplaceItem(props: TicketReplaceItemProps) {
	const {rental, ticket} = props;

	return <div>TicketEndRental</div>;
}
