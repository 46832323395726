import * as React from "react";
import {createElement} from "react";

interface AddressDisplayProps {
	address: {
		addressLine1?: string;
		addressLine2?: string;
		street?: string;
		houseNo?: string;
		zip?: string;
		zipcode?: string;
		city?: string;
		country?: string;
	};
	singleLine?: boolean;
}

export function AddressDisplay(props: AddressDisplayProps) {
	const {address, singleLine} = props;

	const actualAddressLine1 = address.addressLine1 || `${address.street} ${address.houseNo}`.trim();
	const actualZip = address.zip || address.zipcode;

	return (
		<span>
			{actualAddressLine1 && (
				<>
					{actualAddressLine1}
					{singleLine ? ", " : <br />}
				</>
			)}
			{address.addressLine2 && (
				<>
					{address.addressLine2}
					{singleLine ? ", " : <br />}
				</>
			)}
			{address.country}-{actualZip} {address.city}
		</span>
	);
}
