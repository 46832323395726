import {Check} from "@mui/icons-material";
import {Box, Button, Divider, Grid, Link, Typography} from "@mui/material";
import {Now, SiteAccessibility} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {gs} from "../../../consts";
import {useAuthContext} from "../../../context/AuthContextProvider";
import {useSiteContext} from "../../../context/SiteContextProvider";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {LargeIconDisplay} from "../../../displays/LargeIconDisplay";
import {CubesProvider} from "../../../domain/cubes";
import {Item, ItemsProvider, ItemWrapper} from "../../../domain/items";
import {Model, ModelsProvider} from "../../../domain/models";
import {Rental, RentalsProvider} from "../../../domain/rentals";
import {useLocalization} from "../../../i18n";
import {DurationInput} from "../../../inputs/DurationInput";
import {SimpleCheckbox} from "../../../inputs/SimpleCheckbox";
import {TimeOfDay, TimeOfDayInput, timeOfDayNow} from "../../../inputs/TimeOfDayInput";
import AlertDialog from "../../../layout/AlertDialog";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {MobileContainer} from "../../../layout/MobileContainer";
import {PoweredByVariocube} from "../../../layout/PoweredByVariocube";
import {getEndOfAccessibleToday, getUntil} from "../../../utils/TimeUtils";

export function ModelDetailsPage() {
	const {t} = useLocalization();
	const {api} = useAuthContext();
	const navigate = useNavigate();
	const routeParams = useParams();
	const {tenantId, tenant} = useTenantContext();
	const {cubeId} = useSiteContext();

	const modelUuid = routeParams.modelUuid;

	const [model, setModel] = useState<Model>();
	const [item, setItem] = useState<Item>();
	const [rental, setRental] = useState<Rental>();

	const [timeOfDay, setTimeOfDay] = useState<TimeOfDay>(timeOfDayNow());
	const [duration, setDuration] = useState<number>(120);

	const [error, setError] = useState<{title: string; message: string}>();
	const [accessibility, setAccessibility] = useState<SiteAccessibility>();

	useEffect(() => {
		if (api && api.auth && tenantId && modelUuid) {
			const modelsProvider = new ModelsProvider(api);
			modelsProvider.get(tenantId, modelUuid).then(setModel);

			const now = Now.instant().toZonedDateTimeISO("Europe/Vienna");
			const from = now.with({hour: timeOfDay.hours, minute: timeOfDay.minutes});
			const until = getUntil(from, accessibility, duration);

			const itemsProvider = new ItemsProvider(api);
			itemsProvider.availableItemsForModel(tenantId, modelUuid, from, until).then((items) => {
				if (items.content.length > 0) {
					setItem(items.content[0]);
				} else {
					testSetError();
				}
			});
		}
	}, [api, tenantId, modelUuid, timeOfDay, duration]);

	useEffect(() => {
		if (cubeId) {
			const cubeProvider = new CubesProvider(api);
			cubeProvider.getStorageItem(tenantId, cubeId, "accessibility").then((storageItemAccessibility) => {
				setAccessibility(JSON.parse(storageItemAccessibility.value));
			});
		}
	}, [tenantId, cubeId]);

	const testSetError = () => {
		console.log("Set error");
		setError({title: t("models.notavailable.title"), message: t("site.noItemsInfo")});
	};

	const handleBook = useCallback(() => {
		if (api && api.auth && item) {
			const now = Now.instant().toZonedDateTimeISO("Europe/Vienna");
			const from = now.with({hour: timeOfDay.hours, minute: timeOfDay.minutes});
			const until = getUntil(from, accessibility, duration);
			const rentalsProvider = new RentalsProvider(api);
			rentalsProvider.createRental(tenantId, item.uuid, from, until).then(setRental);
		}
	}, [api, tenantId, item, timeOfDay, duration]);

	const itemImage = useMemo<string | undefined>(() => {
		if (item) {
			return new ItemWrapper(item).imageUrl;
		}
		return undefined;
	}, [item]);

	const description = useMemo<string>(() => {
		if (item && item.description) {
			return item.description;
		}
		if (model && model.description) {
			return model.description;
		}
		return "";
	}, [model, item]);

	if (rental) {
		return (
			<MobileContainer showNav={false}>
				<Helmet>
					<title>{rental.itemName}</title>
				</Helmet>
				<FullScreenCard
					actions={
						<Button
							variant="contained"
							color="primary"
							size="large"
							fullWidth
							onClick={() => navigate(`/tenants/${tenantId}/rentals/${rental?.uuid}`)}
						>
							{t("next")}
						</Button>
					}
				>
					<Box p={2}>
						<Grid container spacing={gs}>
							<Grid item xs={12}>
								<PoweredByVariocube />
							</Grid>
							<Grid item xs={12}>
								<LargeIconDisplay
									icon={
										<Check
											color="secondary"
											sx={{
												width: "8em",
												height: "8em"
											}}
										/>
									}
									title={t("models.bookingConfirmed.title")}
									info={t("models.bookingConfirmed.info")}
								/>
							</Grid>
						</Grid>
					</Box>
				</FullScreenCard>
			</MobileContainer>
		);
	}

	return (
		<MobileContainer showNav={false}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{model ? model.name : t("site.bookNow")}</title>
			</Helmet>
			<FullScreenCard
				back
				imageUrl={itemImage}
				paddingTop={!itemImage ? "3em" : undefined}
				actions={
					<BookingActionArea
						timeOfDay={timeOfDay}
						onTimeOfDayChange={setTimeOfDay}
						duration={duration}
						onDurationChange={setDuration}
						maxRentalMinutes={tenant?.maxRentPeriod ? tenant.maxRentPeriod * 60 : undefined}
						onConfirm={handleBook}
						accessibility={accessibility}
					/>
				}
			>
				<Box p={2}>
					<Grid container spacing={gs}>
						{tenant && model && (
							<Fragment>
								<Grid item xs={12}>
									<Typography variant="h3">{model.name}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								{Boolean(tenant.maxRentPeriod) && (
									<Fragment>
										<Grid item xs={12}>
											<Typography variant="body1">
												{t("models.maxRentalTime", {
													time: tenant.maxRentPeriod
												})}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Divider />
										</Grid>
									</Fragment>
								)}
								<Grid item xs={12}>
									<Box
										sx={{
											maxHeight: tenant?.maxRentPeriod ? "9vh" : "10vh",
											overflow: "hidden",
											maskImage: "linear-gradient(to bottom, black 50%, transparent 100%)",
											cursor: "pointer"
										}}
									>
										<Typography variant="body1" sx={{whiteSpace: "pre-wrap"}}>
											{description}
										</Typography>
									</Box>
								</Grid>
							</Fragment>
						)}
					</Grid>
				</Box>
			</FullScreenCard>
			{error && error.title && (
				<AlertDialog
					title={error.title}
					message={error.message}
					onClose={() => {
						setError(undefined);
					}}
				/>
			)}
		</MobileContainer>
	);
}

interface BookingActionAreaProps {
	onConfirm: () => void;
	timeOfDay: TimeOfDay;
	onTimeOfDayChange: (timeOfDay: TimeOfDay) => void;
	duration: number;
	onDurationChange: (duration: number) => void;
	maxRentalMinutes?: number;
	accessibility?: SiteAccessibility;
}

function BookingActionArea(props: BookingActionAreaProps) {
	const {onConfirm, timeOfDay, onTimeOfDayChange, duration, onDurationChange, maxRentalMinutes, accessibility} =
		props;
	const {t} = useLocalization();
	const {tenantId} = useTenantContext();
	const navigate = useNavigate();

	const [terms, setTerms] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const handleConfirm = useCallback(() => {
		setSubmitting(true);
		onConfirm();
	}, [onConfirm]);

	const canConfirm = useMemo<boolean>(() => Boolean(terms), [terms]);

	return (
		<Grid container spacing={gs}>
			<Grid item xs={6}>
				<TimeOfDayInput time={timeOfDay} onChange={onTimeOfDayChange} maxFutureMinutes={30} />
			</Grid>
			<Grid item xs={6}>
				{!accessibility && (
					<DurationInput
						duration={duration}
						onChange={onDurationChange}
						maxFutureMinutes={maxRentalMinutes}
					/>
				)}
				{accessibility && (
					<div style={{padding: 16}}>
						{t("timeframe.until")} {getEndOfAccessibleToday(accessibility)}
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<SimpleCheckbox
					checked={terms}
					onChange={setTerms}
					label={
						<span>
							{t("rentals.termsAndConditionsText")}{" "}
							<Link onClick={() => navigate(`/tenants/${tenantId}/imprint`)}>
								{t("rentals.termsAndConditionsLink")}
							</Link>
						</span>
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<Button
					variant="contained"
					color="primary"
					size="large"
					fullWidth
					onClick={handleConfirm}
					disabled={!canConfirm || submitting}
				>
					{t("models.bookForFree")}
				</Button>
			</Grid>
		</Grid>
	);
}
