import {Api} from "../libs/Api";

interface IpInfo {
	ip: string;
	hostname: string;
	city: string;
	region: string;
	country: string;
	loc: string;
	org: string;
	postal: string;
	timezone: string;
}

export async function ipInfo(): Promise<IpInfo> {
	const url = `https://ipinfo.io?token=1ad38f387ca7ec`;
	const response = await fetch(url, {
		method: "GET"
	});
	return response.json();
}
