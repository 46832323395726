import { Duration, Now, SiteAccessibility, ZonedDateTime } from "@variocube/app-ui";

export function dayOfWeekAsString(dayIndex: number) {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayIndex] || '';
}


export const getStartOfAccessibleToday = (accessibility: SiteAccessibility) => {
    if (accessibility) {
        if (accessibility.alwaysAccessible) {
            return "00:00";
        }
        const dayOfWeek = (new Date()).getDay();
        if (accessibility.weekdayAccessibleHours) {
            const dowTimes = accessibility.weekdayAccessibleHours.find(dow => dow.weekday === dayOfWeekAsString(dayOfWeek));
            return dowTimes && dowTimes.accessibleFrom  ? dowTimes.accessibleFrom : accessibility.accessibleFrom;
        }
    }
}

export const getEndOfAccessibleToday = (accessibility: SiteAccessibility) => {
    if (accessibility) {
        if (accessibility.alwaysAccessible) {
            return "24:00";
        }
        const dayOfWeek = (new Date()).getDay();
        if (accessibility.weekdayAccessibleHours) {
            const dowTimes = accessibility.weekdayAccessibleHours.find(dow => dow.weekday === dayOfWeekAsString(dayOfWeek));
            return dowTimes && dowTimes.accessibleUntil ? dowTimes.accessibleUntil : accessibility.accessibleUntil;
        }
    }
}


//returns the until time for the booking, depending on if the cube has accessibility defined or not
export const getUntil = (from: ZonedDateTime, accessibility?: SiteAccessibility, duration?: number): ZonedDateTime => {
    if (accessibility && accessibility.accessibleUntil) {
        const endOfDay = getEndOfAccessibleToday(accessibility);
        const now = Now.instant().toZonedDateTimeISO("Europe/Vienna");
        const until = now.with({ hour: endOfDay?.split(":")[0] as any, minute: endOfDay?.split(":")[1] as any, second: 0, millisecond: 0, microsecond: 0 });
        return until;
    }
    return from.add(Duration.from({ minutes: duration }));
}

/**
 * 
 * @param timeOnly example "14:00"
 * @returns 
 */
function toZonedDateTime(timeOnly: string| undefined )   {
    const now = Now.instant().toZonedDateTimeISO("Europe/Vienna");
    const until = now.with({ hour: timeOnly?.split(":")[0] as any, minute: timeOnly?.split(":")[1] as any, second: 0, millisecond: 0, microsecond: 0 });
    return until;
}
export const isInAccesssibleTime = (accessibility?: SiteAccessibility) => {
    if (!accessibility || accessibility.alwaysAccessible)
        return true;
    const now = Now.instant().toZonedDateTimeISO("Europe/Vienna");
    const from = toZonedDateTime(getStartOfAccessibleToday(accessibility));
    const until = toZonedDateTime(getEndOfAccessibleToday(accessibility));
    //console.log("compare ", from.toJSON(), until.toJSON(), from.epochSeconds, now.epochSeconds-from.epochSeconds, now.epochSeconds-until.epochSeconds);
    return from.epochSeconds<=now.epochSeconds && now.epochSeconds<=until.epochSeconds ;
}

