import * as React from "react";
import {createElement} from "react";
import {Grid} from "@mui/material";
import {gs} from "../consts";
import {VCLogo} from "@variocube/app-ui";

interface PoweredByVariocubeProps {
}

export function PoweredByVariocube(props: PoweredByVariocubeProps) {

	return (
		<Grid container spacing={gs}>
			<Grid item>
				Powered by
			</Grid>
			<Grid item>
				<VCLogo height={24} width="auto" />
			</Grid>
		</Grid>
	);
}
