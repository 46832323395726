import {alpha, Box, Card, useTheme} from "@mui/material";
import {createElement, PropsWithChildren, ReactNode} from "react";
import {FittingImage} from "../displays/FittingImage";
import {BackButton} from "./BackButton";

type FullScreenCardProps = PropsWithChildren<{
	back?: boolean;
	onBack?: () => void;
	paddingTop?: string | number | undefined;
	imageUrl?: string;
	poweredBy?: boolean;
	actions: ReactNode;
	noOverflow?: boolean;
}>;

export function FullScreenCard(props: FullScreenCardProps) {
	const {back, onBack, paddingTop, imageUrl, poweredBy, actions, noOverflow, children} = props;
	const theme = useTheme();

	return (
		<Box p={2} sx={{height: "100%"}}>
			<Card
				sx={{
					height: !noOverflow ? "100%" : undefined,
					display: !noOverflow ? "flex" : undefined,
					flexDirection: !noOverflow ? "column" : undefined,
					paddingTop: paddingTop,
					overflowY: !noOverflow ? "scroll" : undefined,
					backgroundColor: alpha(theme.palette.background.paper, 0.95)
				}}
			>
				{(back || onBack) && <BackButton onBack={onBack} padding={4} />}
				{imageUrl && (
					<div style={{minHeight: "12em"}}>
						<FittingImage src={imageUrl} minHeight={"12em"} />
					</div>
				)}
				<div
					style={{
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",
						height: "100%"
					}}
				>
					<div style={{flexGrow: 1}}>{children}</div>
					<div>
						<Box p={2}>{actions}</Box>
					</div>
				</div>
				{poweredBy && <div>Powered by</div>}
			</Card>
		</Box>
	);
}
