import {createElement, PropsWithChildren} from "react";
import {Avatar, Box, Grid, useTheme} from "@mui/material";

interface InstructionProps extends PropsWithChildren<{pos: number}> {}

export function Instruction(props: InstructionProps) {
	const {pos, children} = props;
	const theme = useTheme();
	return (
		<Grid container spacing={4}>
			<Grid item xs={1}>
				<Avatar sx={{backgroundColor: theme.palette.secondary.main}}>{pos}</Avatar>
			</Grid>
			<Grid item xs={11} sx={{flexGrow: 1}}>
				<Box paddingTop={1}>{children}</Box>
			</Grid>
		</Grid>
	);
}
