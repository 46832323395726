import {Box, CircularProgress} from "@mui/material";
import {WebAuth} from "auth0-js";
import {createElement, useEffect} from "react";
import {Helmet} from "react-helmet";
import {useAuthContext} from "../../context/AuthContextProvider";
import {LargeIconDisplay} from "../../displays/LargeIconDisplay";
import {AuthenticatedToken} from "../../domain/auth";
import {getSignupDomain} from "../../domain/tenants";
import {UsersProvider} from "../../domain/users";
import {useLocalization} from "../../i18n";
import {FullScreenCard} from "../../layout/FullScreenCard";
import {MobileContainer} from "../../layout/MobileContainer";
import {Api} from "../../libs/Api";

export const AUTH0_CLIENT_ID = "U3AZMRtppUtLNrg0fKOVhgbZxjQjS9EE";
export const REDIRECT_URI = `${location.protocol}//${location.host}/auth`;
export const RETURN_URI = `${location.protocol}//${location.host}/`;

export const auth0 = new WebAuth({
	domain: "variocube.eu.auth0.com",
	clientID: AUTH0_CLIENT_ID,
	redirectUri: REDIRECT_URI,
	audience: "https://rental.variocube.com/",
	responseType: "token id_token",
	scope: "openid"
});

export function LoginPage() {
	const {t} = useLocalization();

	useEffect(() => {
		auth0.authorize();
	}, []);

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard actions={[]}>
				<Box p={2}>
					<LargeIconDisplay icon={<CircularProgress color="secondary" />} title={t("auth.loginForward")} />
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}

export function ProcessTokenPage() {
	const {provideToken} = useAuthContext();
	const {t} = useLocalization();

	useEffect(() => {
		auth0.parseHash((error, authResult) => {
			if (error) {
				console.log("Could not process authentication callback", error);
				return;
			}
			if (authResult && authResult.accessToken && authResult.idToken) {
				console.log("Processing auth result");

				const token = new AuthenticatedToken({
					idToken: authResult.idToken,
					accessToken: authResult.accessToken,
					refreshToken: authResult.refreshToken || "",
					expiresIn: authResult.expiresIn || 0,
					tokenType: "Bearer"
				});

				const api = new Api("/", token);
				const usersProvider = new UsersProvider(api);
				usersProvider
					.userEnsureTenantAssigned(getSignupDomain())
					.then(() => {
						provideToken(token);
						setTimeout(() => {
							window.location.replace("/");
						}, 1000);
					})
					.catch((e) => {
						console.error("Could not ensure tenant access", e);
					});
			}
		});
	}, []);

	return (
		<MobileContainer showNav={false}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t("auth.processingToken")}</title>
			</Helmet>
			<FullScreenCard actions={[]}>
				<Box p={2}>
					<LargeIconDisplay icon={<CircularProgress color="secondary" />} title={t("auth.processingToken")} />
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}

export function LogoutPage() {
	const {logout} = useAuthContext();
	const {t} = useLocalization();

	useEffect(() => {
		logout();
		auth0.logout({
			returnTo: RETURN_URI,
			clientID: AUTH0_CLIENT_ID
		});
	}, [logout]);

	return (
		<MobileContainer showNav={false}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t("auth.logoutForward")}</title>
			</Helmet>
			<FullScreenCard actions={[]}>
				<Box p={2}>
					<LargeIconDisplay icon={<CircularProgress color="secondary" />} title={t("auth.logoutForward")} />
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}
