import {Assignment, SupportAgent} from "@mui/icons-material";
import {Box, Divider, Grid, Link, Typography} from "@mui/material";
import {createElement, Fragment, useMemo} from "react";
import {Helmet} from "react-helmet";
import {gs} from "../../../consts";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {AddressDisplay} from "../../../displays/AddressDisplay";
import {useLocalization} from "../../../i18n";
import {HeaderWithIcon} from "../../../layout/HeaderWithIcon";
import {InfoCard} from "../../../layout/InfoCard";
import {Loading} from "../../../layout/Loading";
import {MobileContainer} from "../../../layout/MobileContainer";
import {PoweredByVariocube} from "../../../layout/PoweredByVariocube";

export function ImprintPage() {
	const {t, language} = useLocalization();
	const {tenantId, tenant} = useTenantContext();

	const imprintInfo = useMemo<string | undefined>(() => {
		if (tenant && language && tenant.rentalInfo) {
			const rentalInfo = tenant.rentalInfo[language];
			if (rentalInfo) {
				return rentalInfo;
			}
			return tenant.rentalInfo[0];
		}
		return undefined;
	}, [language, tenant]);

	return (
		<MobileContainer showNav={true}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t("imprint.title")}</title>
			</Helmet>
			<InfoCard>
				<Box p={2}>
					<Grid container spacing={gs}>
						{!imprintInfo == undefined && (
							<Grid item xs={12}>
								<Loading />
							</Grid>
						)}
						{imprintInfo && (
							<Fragment>
								<Grid item xs={12}>
									<HeaderWithIcon title={t("imprint.title")} icon={<Assignment />} variant="h3" />
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Box paddingLeft={5}>
										<Typography variant="body1">
											<div dangerouslySetInnerHTML={{__html: imprintInfo}}></div>
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box p={3} />
								</Grid>
							</Fragment>
						)}
						{!tenant && (
							<Grid item xs={12}>
								<Loading />
							</Grid>
						)}
						{tenant && (
							<Fragment>
								<Grid item xs={12}>
									<HeaderWithIcon
										title={t("support")}
										icon={<SupportAgent color="secondary" />}
										variant="h3"
									/>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Box paddingLeft={5}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Typography variant="body1">{tenant.name}</Typography>
											</Grid>
											{tenant.address && (
												<Grid item xs={12}>
													<AddressDisplay address={tenant.address} />
												</Grid>
											)}
											{tenant.phone && (
												<Grid item xs={12}>
													<Typography variant="body1">
														Tel.: <Link href={`tel:${tenant.phone}`}>{tenant.phone}</Link>
													</Typography>
												</Grid>
											)}
											{tenant.email && (
												<Grid item xs={12}>
													<Typography variant="body1">
														E-Mail.:{" "}
														<Link href={`mailto:${tenant.email}`}>{tenant.email}</Link>
													</Typography>
												</Grid>
											)}
										</Grid>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Box paddingLeft={5}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Link
													href={`https://variocube-docs.s3.eu-west-1.amazonaws.com/variocube.lend/${tenantId}/terms.pdf`}
													underline="always"
												>
													AGBs
												</Link>
											</Grid>
											<Grid item xs={12}>
												<Link
													href={`https://variocube-docs.s3.eu-west-1.amazonaws.com/variocube.lend/${tenantId}/imprint.pdf`}
													underline="always"
												>
													{t("imprint.title")}
												</Link>
											</Grid>
											<Grid item xs={12}>
												<Link
													href={`https://variocube-docs.s3.eu-west-1.amazonaws.com/variocube.lend/${tenantId}/privacy-policy.pdf`}
													underline="always"
												>
													Datenschutzerklärung
												</Link>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Fragment>
						)}
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<Box paddingLeft={5} paddingRight={5} sx={{overflowX: "hidden"}}>
								<PoweredByVariocube />
							</Box>
						</Grid>
					</Grid>
				</Box>
			</InfoCard>
		</MobileContainer>
	);
}
