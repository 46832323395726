import {createElement} from "react";

interface FittingImageProps {
	src: string;
	minHeight?: string;
}

export function FittingImage(props: FittingImageProps) {
	const {src, minHeight} = props;

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				backgroundImage: `url("${src}")`,
				backgroundSize: "cover",
				backgroundPosition: "center center",
				borderRadius: "8px 8x 0 0",
				minHeight: minHeight
			}}
		/>
	);
}
