import {Container, Grid, StyledEngineProvider} from "@mui/material";
import {Branding, ErrorBoundary, VCThemeProvider} from "@variocube/app-ui";
import {createElement, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {BrowserRouter, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {gs} from "./consts";
import {AuthContextProvider, useAuthContext} from "./context/AuthContextProvider";
import {SiteContextProvider} from "./context/SiteContextProvider";
import {TenantContextProvider} from "./context/TenantContextProvider";
import {usePublicApi} from "./domain/tenants";
import {StorageLocalizationProvider} from "./i18n";
import {LoginPage, LogoutPage, ProcessTokenPage} from "./pages/auth";
import {SignupPage} from "./pages/auth/SignupPage";
import {LandingPage} from "./pages/LandingPage";
import {ImprintPage} from "./pages/tenants/imprint/ImprintPage";
import {ModelDetailsPage} from "./pages/tenants/models/ModelDetailsPage";
import {ModelsPage} from "./pages/tenants/models/ModelsPage";
import {ProfilePage} from "./pages/tenants/profile/ProfilePage";
import {RentalDetailsView} from "./pages/tenants/rentals/RentalDetailsView";
import {RentalsList} from "./pages/tenants/rentals/RentalsList";
import {TicketPage} from "./pages/tenants/rentals/ticket/TicketPage";
import {SiteDetails} from "./pages/tenants/sites/SiteDetails";
import {SiteSelect} from "./pages/tenants/sites/SiteSelect";
import {TenantSelect} from "./pages/tenants/TenantSelect";

interface AppProps {}

export function App(props: AppProps) {
	const [initializing, setInitializing] = useState<boolean>(true);
	const [branding, setBranding] = useState<Branding>();
	const {getBranding} = usePublicApi();

	useEffect(() => {
		getBranding()
			.then(setBranding)
			.finally(() => setInitializing(false));
	}, []);

	if (initializing) {
		return null;
	}

	return (
		<StyledEngineProvider injectFirst>
			<VCThemeProvider branding={branding}>
				<StorageLocalizationProvider>
					<Helmet>
						<meta charSet="utf-8" />
						<title>VARIOCUBE Lending loading ...</title>
					</Helmet>
					<ErrorBoundary>
						<AuthContextProvider notAuthenticatedUrl="/login">
							<BrowserRouter>
								<AppRoutes />
							</BrowserRouter>
						</AuthContextProvider>
					</ErrorBoundary>
				</StorageLocalizationProvider>
			</VCThemeProvider>
		</StyledEngineProvider>
	);
}

function AppRoutes() {
	return (
		<Routes>
			<Route index element={<Index />} />
			<Route path="/landing" element={<LandingPage />} />
			<Route path="/signup" element={<SignupPage />} />
			<Route path="/login" element={<LoginPage />} />
			<Route path="/auth" element={<ProcessTokenPage />} />
			<Route path="/logout" element={<LogoutPage />} />

			<Route path="/tenants" element={<TenantSelect />} />

			<Route
				path="/tenants/:tenantId"
				element={
					<TenantContextProvider>
						<Outlet />
					</TenantContextProvider>
				}
			>
				<Route index element={<SiteSelect />} />

				<Route
					path="sites/:cubeId"
					element={
						<SiteContextProvider>
							<Outlet />
						</SiteContextProvider>
					}
				>
					<Route index element={<SiteDetails />} />
					<Route path="models" element={<ModelsPage />} />
					<Route path="models/:modelUuid" element={<ModelDetailsPage />} />
				</Route>

				<Route path="rentals/:rentalUuid/ticket" element={<TicketPage />} />
				<Route path="rentals/:rentalUuid" element={<RentalDetailsView />} />
				<Route path="rentals" element={<RentalsList />} />

				<Route path="profile" element={<ProfilePage />} />
				<Route path="imprint" element={<ImprintPage />} />
			</Route>
		</Routes>
	);
}

function Index() {
	const {api} = useAuthContext();

	const navigate = useNavigate();

	const [checking, setChecking] = useState<boolean>(true);

	useEffect(() => {
		if (api.auth && api.auth.isLoggedIn) {
			navigate("/tenants");
		} else {
			navigate("/landing");
		}
		setChecking(false);
	}, [api]);

	return (
		<Container maxWidth="lg">
			<Grid container spacing={gs}>
				{checking ? "Checking ..." : "Redirecting ..."}
			</Grid>
		</Container>
	);
}
