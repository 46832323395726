import {Button, Grid, Typography} from "@mui/material";
import * as React from "react";
import {createElement} from "react";
import keypad from "../../../assets/keypad.png";
import {gs} from "../../../consts";
import {Rental} from "../../../domain/rentals";
import {useLocalization} from "../../../i18n";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {MobileContainer} from "../../../layout/MobileContainer";

interface OfflineEndRentalScreenProps {
	rental: Rental;
	onEndRental: () => void;
}

export function OfflineEndRentalScreen(props: OfflineEndRentalScreenProps) {
	const {rental, onEndRental} = props;
	const {t} = useLocalization();

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard
				actions={
					<Button variant="contained" color="error" fullWidth onClick={onEndRental}>
						{t("rentals.offline.endRental.return")}
					</Button>
				}
				back
			>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Typography align="center">
							<img src={keypad} style={{width: "55%"}} alt={t("rentals.offline.openBox.instructions")} />
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h3" align="center">
							{t("rentals.offline.endRental.title")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1" align="center">
							{t("rentals.offline.endRental.instructions")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h2" align="center">
							{rental.borrower ? `0${rental.borrower.accessCode}` : "Not available"}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2" align="center">
							{t("rentals.offline.endRental.info")}
						</Typography>
					</Grid>
				</Grid>
			</FullScreenCard>
		</MobileContainer>
	);
}
