import {Api} from "../libs/Api";
import {Branding, createQueryString} from "@variocube/app-ui";

export interface Tenant {
	centerId: string;
	name: string;
	address?: {
		street?: string;
		houseNo?: string;
		zip?: string;
		city?: string;
		country?: string;
	};
	email?: string;
	phone?: string;
	signUpDomains: string[];
	rentalInfo: {
		[language: string]: string;
	};
	maxRentPeriod?: number;
	maxRentals?: number;
	requiredEmailVerificationForSignUp: boolean;
	requiredPhoneForSignUp: boolean;
	requiredPhoneVerificationForSignUp: boolean;
	requiredAddressForSignUp: boolean;
	requiredNameForSignUp: boolean;
}

export class TenantsProvider {
	readonly api: Api;

	constructor(api: Api) {
		this.api = api;
	}

	async list(): Promise<Tenant[]> {
		const url = "/tenants";
		return this.api.get<Tenant[]>(url);
	}

	async get(tenantId: string): Promise<Tenant> {
		const url = `/tenants/${tenantId}`;
		return this.api.get<Tenant>(url);
	}
}


export interface VerifyEmailResponse {
	email: string;
	existed: boolean;
}

export interface AddVerificationRequest {
	email?: string;
	phone?: string;
}

export interface VerificationRequest {
	type: 'Email'|'Phone';
	code: string;
	value: string;
}

export interface VerificationResult {
	code: string;
	valid: boolean;
}

export function usePublicApi() {
	const api = new Api("/");
	return {
		findTenantBySignupDomain: async (domain: string) => {
			const url = `/public/signup-domain?${createQueryString({ domain })}`;
			return api.get<Tenant>(url);
		},
		getBranding: async () => {
			const url = `/public/${getSignupDomain()}/branding`;
			return api.get<Branding>(url);
		},
		verifyEmail: async (centerId: string, email: string) => {
			const url = `/public/${centerId}/verify-email?${createQueryString({ email })}`;
			return api.get<VerifyEmailResponse>(url);
		},
		addSignupVerification: async (centerId: string, request: AddVerificationRequest) => {
			const url = `/public/${centerId}/add-signup-verification`;
			return api.post(url, request);
		},
		verifySignUpCode: async (request: VerificationRequest) => {
			const url = `/public/verify-signup-code`;
			return api.post<VerificationResult>(url, request);
		}
	}
}

export function getSignupDomain(): string {
	const hostname = window.location.hostname;
	if (hostname == "localhost") {
		return localStorage.getItem("signupDomain") || "lend.variocube.com";
	}
	return hostname;
}
