import {Temporal} from "@js-temporal/polyfill";
import {Instant} from "@variocube/app-ui";
import {createElement, useEffect, useState} from "react";
import {Rental} from "../../../domain/rentals";

export type RemainingTimeProps = {
	rental: Rental;
	fontSize?: string;
};
export function RemainingTime(props: RemainingTimeProps) {
	const {rental, fontSize} = props;
	const [remainingTime, setRemainingTime] = useState<string>();

	useEffect(() => {
		if (rental) {
			// TODO check if cube is online
			const remainsTime = remainingTimeFunc(rental.until);
			setRemainingTime(remainsTime);
		}
	}, [rental]);

	setTimeout(() => {
		if (rental) {
			const remainsTime = remainingTimeFunc(rental.until);
			setRemainingTime(remainsTime);
		}
	}, 1000);

	const rtc = (remainingTime: string | undefined) => {
		return remainingTime && remainingTime?.indexOf("-") >= 0 ? "red" : "green";
	};

	return (
		<div style={{fontSize: fontSize || "xxx-large", color: rtc(remainingTime)}}>
			{rental && rental.until && remainingTime}
		</div>
	);
}

function remainingTimeFunc(until: Instant) {
	var remainsSecs = until.epochSeconds - Temporal.Now.instant().epochSeconds;
	const minus = Math.sign(remainsSecs);
	remainsSecs = Math.abs(remainsSecs);
	const hours = Math.trunc(remainsSecs / (60 * 60));
	const mins = Math.trunc((remainsSecs - hours * 60 * 60) / 60);
	const secs = Math.trunc(remainsSecs - hours * 60 * 60 - mins * 60);
	const remainsTime = (minus < 0 ? "-" : "")
		+ hours
		+ ":"
		+ mins.toString().padStart(2, "0")
		+ ":"
		+ secs.toString().padStart(2, "0");
	return remainsTime;
}
