import * as React from "react";
import {createElement, useMemo} from "react";
import {Tooltip} from "@mui/material";

interface ShortenedDisplayProps {
	value: string;
	maxLen: number;
}

export function ShortenedDisplay(props: ShortenedDisplayProps) {
	const {value, maxLen} = props;

	const shortened = useMemo<string>(() => {
		if (value.length <= maxLen) {
			return value;
		}
		return value.substring(0, maxLen) + "...";
	}, [value, maxLen]);

	return (
		<Tooltip title={value}>
			<span>{shortened}</span>
		</Tooltip>
	);
}
