import * as React from "react";
import {createElement, useCallback, useMemo} from "react";
import {TextField} from "@variocube/app-ui";
import {InputAdornment, MenuItem} from "@mui/material";
import {useLocalization} from "../i18n";

export interface TimeOfDay {
	hours: number;
	minutes: number;
}

interface TimeOfDayInputProps {
	time: TimeOfDay;
	onChange: (value: TimeOfDay) => void;
	maxFutureMinutes?: number;
}

export function TimeOfDayInput(props: TimeOfDayInputProps) {
	const {time, onChange, maxFutureMinutes = 12 * 60} = props;
	const {t} = useLocalization();

	const handleChange = useCallback(
		(value: string) => {
			const [hours, minutes] = value.split(":").map((v) => parseInt(v));
			onChange({hours, minutes});
		},
		[onChange]
	);

	const formattedTime = useMemo<string>(() => formatTimeOfDay(time), [time]);

	const options = useMemo<TimeOfDay[]>(() => {
		const now = timeOfDayNow();
		let future = {hours: now.hours, minutes: Math.ceil(now.minutes / 15) * 15};
		const limit = add(now, {hours: Math.floor(maxFutureMinutes / 60), minutes: maxFutureMinutes % 60});
		const options = [now, future];
		while (before(future, limit)) {
			future = add(future, {hours: 0, minutes: 15});
			options.push(future);
		}
		return options;
	}, [time, maxFutureMinutes]);

	return (
		<TextField
			value={formattedTime}
			onChange={handleChange}
			select
			fullWidth
			InputProps={{
				startAdornment: <InputAdornment position="start">{t("timeOfDayInput.from")}</InputAdornment>
			}}
		>
			{options.map((option) => (
				<MenuItem key={formatTimeOfDay(option)} value={formatTimeOfDay(option)}>
					{formatTimeOfDay(option)}
				</MenuItem>
			))}
		</TextField>
	);
}

function formatTimeOfDay(time: TimeOfDay): string {
	return `${time.hours.toString().padStart(2, "0")}:${time.minutes.toString().padStart(2, "0")}`;
}

export function timeOfDayNow(): TimeOfDay {
	const now = new Date();
	return {hours: now.getHours(), minutes: now.getMinutes()};
}

function add(a: TimeOfDay, b: TimeOfDay): TimeOfDay {
	const minutes = a.minutes + b.minutes;
	const hours = a.hours + b.hours + Math.floor(minutes / 60);
	return {hours: hours % 24, minutes: minutes % 60};
}

function before(a: TimeOfDay, limit: TimeOfDay): boolean {
	return a.hours < limit.hours || (a.hours == limit.hours && a.minutes < limit.minutes);
}
