import {createLocalizationContext} from "@variocube/app-ui";

export const {StorageLocalizationProvider, useLocalization} = createLocalizationContext<typeof import("./en.json")>({
	load: (lang) => import(`./${lang}.json`),
	missing: location.hostname == "localhost" ? (key) => `??${key}??` : "fallback",
	fallback: "en"
});

export const SUPPORTED_LANGUAGES = [
	{language: "en", displayName: "English"},
	{language: "de", displayName: "Deutsch"}
] as const;

export function useTimeframeMessage() {
	const {t} = useLocalization();
	return {
		timeframeTitle: t("timeframe.title"),
		from: t("timeframe.from"),
		until: t("timeframe.until"),
		types: {
			All: t("timeframe.types.All"),
			Today: t("timeframe.types.Today"),
			Yesterday: t("timeframe.types.Yesterday"),
			Last7Days: t("timeframe.types.Last7Days"),
			LastWeek: t("timeframe.types.LastWeek"),
			ThisMonth: t("timeframe.types.ThisMonth"),
			LastMonth: t("timeframe.types.LastMonth"),
			Last3Months: t("timeframe.types.Last3Months"),
			LastYear: t("timeframe.types.LastYear"),
			Custom: t("timeframe.types.Custom")
		}
	};
}
