import {Box, Button, Divider, Grid, Link, Typography} from "@mui/material";
import {createElement, Fragment, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {gs} from "../consts";
import {getSignupDomain, Tenant, usePublicApi} from "../domain/tenants";
import {useLocalization} from "../i18n";
import {InfoCard} from "../layout/InfoCard";
import {MobileContainer} from "../layout/MobileContainer";

interface LandingPageProps {}

export function LandingPage(props: LandingPageProps) {
	const navigate = useNavigate();
	const {t} = useLocalization();

	const {findTenantBySignupDomain} = usePublicApi();
	const [tenant, setTenant] = useState<Tenant>();

	useEffect(() => {
		findTenantBySignupDomain(getSignupDomain()).then(setTenant);
	}, [findTenantBySignupDomain]);

	return (
		<MobileContainer showNav={true}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t("landing.title")}</title>
			</Helmet>
			<InfoCard>
				<Box p={2}>
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<Typography variant="h1">{t("landing.title")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1">{t("landing.loginInfo1")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1">{t("landing.loginInfo2")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography align="center">
								<Button variant="contained" color="primary" onClick={() => navigate("/login")}>
									{t("login")}
								</Button>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1">{t("landing.signupInfo")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography align="center">
								<Button variant="contained" color="secondary" onClick={() => navigate("/signup")}>
									{t("landing.signup")}
								</Button>
							</Typography>
						</Grid>
						{tenant && (
							<Fragment>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Typography align="right">
										<Link
											href={`https://variocube-docs.s3.eu-west-1.amazonaws.com/variocube.lend/${tenant.centerId}/imprint.pdf`}
											underline="always"
											target="imprint"
										>
											{t("imprint.title")}
										</Link>
									</Typography>
								</Grid>
							</Fragment>
						)}
					</Grid>
				</Box>
			</InfoCard>
		</MobileContainer>
	);
}
