import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import {createElement} from "react";
import {useNavigate} from "react-router-dom";
import {gs} from "../../../consts";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {Instruction} from "../../../displays/Instruction";
import {Item} from "../../../domain/items";
import {Rental} from "../../../domain/rentals";
import {useLocalization} from "../../../i18n";
import {VCBox} from "../../../layout/Box";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {MobileContainer} from "../../../layout/MobileContainer";

interface EndRentalScreenProps {
	item: Item;
	rental: Rental;
	allowTicket?: boolean;
	onNext: () => void;
}

export function EndRentalBoxOpenScreen(props: EndRentalScreenProps) {
	const {item, rental, allowTicket, onNext} = props;
	const navigate = useNavigate();
	const {tenantId} = useTenantContext();
	const {t} = useLocalization();

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard
				actions={
					<Button variant="contained" color="primary" fullWidth onClick={() => onNext()}>
						{t("next")}
					</Button>
				}
			>
				<Box p={2}>
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<Typography variant="h2" align="center">
								{t("rentals.return.title")}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Box p={2} />
						</Grid>
						<Grid item xs={12}>
							<Instruction pos={1}>
								<Typography variant="body1" sx={{fontSize: "large"}}>
									{t("rentals.return.complete")}
								</Typography>
								{allowTicket && (
									<Box paddingTop={2}>
										<Button
											variant="outlined"
											color="error"
											onClick={() =>
												navigate(`/tenants/${tenantId}/rentals/${rental.uuid}/ticket`)
											}
										>
											{t("rent.reportProblem")}
										</Button>
									</Box>
								)}
							</Instruction>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<Instruction pos={2}>
								<Typography variant="body1" sx={{fontSize: "large"}}>
									{t("rentals.return.removedPrivateItems")}
								</Typography>
							</Instruction>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<Instruction pos={2}>
								<Typography variant="body1" sx={{fontSize: "large"}}>
									{t("rentals.return.putEverythingBack")}
								</Typography>
							</Instruction>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<Instruction pos={2}>
								<Typography variant="body1" sx={{fontSize: "large"}}>
									{t("rentals.return.closeBox")}
								</Typography>
							</Instruction>
						</Grid>
						{item && item.stored && (
							<Grid item xs={12}>
								<VCBox boxNumber={item?.stored?.boxNumber} />
							</Grid>
						)}
					</Grid>
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}
