import {createElement} from "react";

interface SvgIconDisplayProps {
	src: string;
	alt: string;
	style?: React.CSSProperties;
}

export function SvgIconDisplay(props: SvgIconDisplayProps) {
	const {src, alt, style = {width: "24px", height: "24px"}} = props;

	return <img src={src} alt={alt} style={style} />;
}
