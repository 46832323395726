import {ChevronLeft} from "@mui/icons-material";
import {Box, Button, useTheme} from "@mui/material";
import {createElement} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalization} from "../i18n";

interface BackButtonProps {
	onBack?: () => void;
	padding: number;
}

export function BackButton({onBack, padding}: BackButtonProps) {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const theme = useTheme();

	function handleBack() {
		if (onBack) {
			onBack();
		} else {
			navigate(-1);
		}
	}

	return (
		<Box position="absolute" top={theme.spacing(padding)} left={theme.spacing(padding)}>
			<Button variant="contained" color="primary" size="small" onClick={handleBack} startIcon={<ChevronLeft />}>
				{t("back")}
			</Button>
		</Box>
	);
}
