import {
	createContext,
	createElement,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import {useParams} from "react-router";
import {Tenant, TenantsProvider} from "../domain/tenants";
import {useAuthContext} from "./AuthContextProvider";

interface TenantContextProps extends PropsWithChildren<any> {
}

interface TenantContextData {
	tenantId: string;
	tenant?: Tenant;
	provideTenant: (tenant: Tenant) => void;
}

const emptyContext: TenantContextData = {
	tenantId: "",
	provideTenant: (tenant: Tenant) => {}
};

export const TenantContext = createContext<TenantContextData>(emptyContext);

export function TenantContextProvider(props: TenantContextProps) {
	const {children} = props;
	const routeParams = useParams();
	const {api} = useAuthContext();

	const routeParamsTenantId = routeParams.tenantId;

	const [tenantId, setTenantId] = useState<string>("");
	const [tenant, setTenant] = useState<Tenant | undefined>(undefined);

	useEffect(() => {
		if (routeParamsTenantId) {
			setTenantId(routeParamsTenantId);
			if (api && api.auth) {
				const tenantsProvider = new TenantsProvider(api);
				tenantsProvider.get(routeParamsTenantId).then(setTenant);
			}
		} else {
			throw new Error("Cannot find tenantId in route parameters.");
		}
	}, [routeParamsTenantId]);

	const provideTenant = useCallback((tenant: Tenant) => {
		setTenantId(tenant.centerId);
		setTenant(tenant);
	}, [setTenant, setTenantId]);

	const currentContext = useMemo<TenantContextData>(() => ({
		tenantId,
		tenant,
		provideTenant
	}), [tenantId, tenant, setTenantId]);

	return (
		<TenantContext.Provider value={currentContext}>{children}</TenantContext.Provider>
	);
}

export function useTenantContext() {
	return useContext(TenantContext);
}
