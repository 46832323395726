import {Help} from "@mui/icons-material";
import {Box, Button, Grid} from "@mui/material";
import {createElement} from "react";
import {gs} from "../../../consts";
import {LargeIconDisplay} from "../../../displays/LargeIconDisplay";
import {Item} from "../../../domain/items";
import {Rental} from "../../../domain/rentals";
import {useLocalization} from "../../../i18n";
import {VCBox} from "../../../layout/Box";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {MobileContainer} from "../../../layout/MobileContainer";

interface OpenBoxInstructionsScreenProps {
	item: Item;
	rental: Rental;
	allowKeep: boolean;
	onOpenBox: () => void;
	onEndRental: () => void;
	onBack: () => void;
}

export function OpenBoxInstructionsScreen(props: OpenBoxInstructionsScreenProps) {
	const {item, rental, allowKeep, onOpenBox, onEndRental, onBack} = props;
	const {t} = useLocalization();

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard
				onBack={() => onBack()}
				actions={
					<Grid container spacing={gs}>
						{allowKeep && (
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="success"
									size="large"
									fullWidth
									onClick={() => onOpenBox()}
								>
									{t("rentals.return.howToContinue.keep")}
								</Button>
							</Grid>
						)}
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="error"
								size="large"
								fullWidth
								onClick={() => onEndRental()}
							>
								{t("rentals.return.howToContinue.end")}
							</Button>
						</Grid>
					</Grid>
				}
			>
				<Box p={2}>
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<LargeIconDisplay
								icon={
									<Help
										color="secondary"
										sx={{
											width: "5em",
											height: "5em"
										}}
									/>
								}
								title={t("rentals.return.howToContinue.title")}
								size={5}
							/>
						</Grid>
						{item && item.stored && (
							<Grid item xs={12}>
								<VCBox boxNumber={item.stored.boxNumber} />
							</Grid>
						)}
					</Grid>
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}
