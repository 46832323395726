import {ThumbUp} from "@mui/icons-material";
import {Box, Button, Grid} from "@mui/material";
import {createElement} from "react";
import {useNavigate} from "react-router-dom";
import {gs} from "../../../../consts";
import {useTenantContext} from "../../../../context/TenantContextProvider";
import {LargeIconDisplay} from "../../../../displays/LargeIconDisplay";
import {Rental} from "../../../../domain/rentals";
import {Ticket} from "../../../../domain/tickets";
import {useLocalization} from "../../../../i18n";
import {FullScreenCard} from "../../../../layout/FullScreenCard";
import {MobileContainer} from "../../../../layout/MobileContainer";

interface TicketContinueRentalProps {
	rental: Rental;
	ticket: Ticket;
}

export function TicketContinueRental(props: TicketContinueRentalProps) {
	const {rental, ticket} = props;
	const {t} = useLocalization();
	const {tenantId} = useTenantContext();
	const navigate = useNavigate();

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard
				actions={
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								onClick={() => navigate(`/tenants/${tenantId}/rentals/${rental.uuid}`)}
							>
								{t("ticket.howToContinue.continueRental")}
							</Button>
						</Grid>
					</Grid>
				}
			>
				<Box p={2}>
					<LargeIconDisplay
						title={t("ticket.thanks.title")}
						info={t("ticket.thanks.info")}
						icon={
							<ThumbUp
								color="secondary"
								sx={{
									width: "5em",
									height: "5em"
								}}
							/>
						}
					/>
				</Box>
			</FullScreenCard>
		</MobileContainer>
	);
}
