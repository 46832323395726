
import { Fragment, createElement, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../../../context/AuthContextProvider";
import { useTenantContext } from "../../../context/TenantContextProvider";
import { RentalsProvider, RentalWrapper } from "../../../domain/rentals";
import { useLocalization } from "../../../i18n";
import AlertDialog from "../../../layout/AlertDialog";
import { useSiteContext } from "../../../context/SiteContextProvider";
import { CubesProvider } from "../../../domain/cubes";
import { SiteAccessibility } from "@variocube/app-ui";
import { isInAccesssibleTime } from "../../../utils/TimeUtils";

export function RentalConditions(props: { onConfirmConditions: (conditionsMet: boolean) => any }) {
	const { api } = useAuthContext();
	const { t } = useLocalization();

	const [rentals, setRentals] = useState<RentalWrapper[]>();
	const { tenantId, tenant } = useTenantContext();
	const { cubeId } = useSiteContext();
	const [accessibility, setAccessibility] = useState<SiteAccessibility>();

	useEffect(() => {
		if (api && api.auth && tenantId) {
			const rentalsProvider = new RentalsProvider(api);
			rentalsProvider.list(tenantId).then((page) => setRentals(page.content.map((r) => new RentalWrapper(r))));
		}
	}, [api, tenantId]);

	useEffect(() => {
		if (cubeId) {
			const cubeProvider = new CubesProvider(api);
			cubeProvider.getStorageItem(tenantId, cubeId, 'accessibility').then(storageItemAccessibility => {
				setAccessibility(JSON.parse(storageItemAccessibility.value));
			});
		}
	}, [tenantId, cubeId]);


	const currentRentals = useMemo<RentalWrapper[]>(() => {
		if (rentals) {
			return rentals.filter((r) => r.isActive());
		}
		return [];
	}, [rentals]);

	if (currentRentals && tenant && tenant.maxRentals && currentRentals.length >= tenant.maxRentals) {
		return (
			<AlertDialog title={t("rentals.maxRentals.title")} message={t("rentals.maxRentals.text")} onClose={() => { props.onConfirmConditions(false) }} />
		);
	}


	if (accessibility && !isInAccesssibleTime(accessibility)) {
		//console.error('Not in accessible times!');
		return (<AlertDialog title={t("rentals.notAccessible.title")} message={t("rentals.notAccessible.text")} onClose={() => { props.onConfirmConditions(false) }} />);
	}
	return (<Fragment></Fragment>);
	//for debugging 
	//return (<Fragment>Ok, you can rent {currentRentals.length} von {tenant?.maxRentals} on {tenant?.name}</Fragment>);
}

