import {Help} from "@mui/icons-material";
import {Box, Button, Card, CardMedia, Divider, Grid, Stack, Typography} from "@mui/material";
import {TemporalFormat, TemporalRangeFormat} from "@variocube/app-ui";
import {createElement, Fragment, useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {gs} from "../../../consts";
import {useAuthContext} from "../../../context/AuthContextProvider";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {ItemsProvider, ItemWrapper} from "../../../domain/items";
import {RentalsProvider, RentalWrapper} from "../../../domain/rentals";
import {useLocalization} from "../../../i18n";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {Loading} from "../../../layout/Loading";
import {MobileContainer} from "../../../layout/MobileContainer";
import {RemainingTime} from "./RemainingTime";

export function RentalsList() {
	const {api} = useAuthContext();
	const navigate = useNavigate();
	const {tenantId} = useTenantContext();
	const {t} = useLocalization();

	const [rentals, setRentals] = useState<RentalWrapper[]>();

	useEffect(() => {
		if (api && api.auth && tenantId) {
			const rentalsProvider = new RentalsProvider(api);
			rentalsProvider.list(tenantId).then((page) => setRentals(page.content.map((r) => new RentalWrapper(r))));
		}
	}, [api, tenantId]);

	const currentRentals = useMemo<RentalWrapper[]>(() => {
		if (rentals) {
			return rentals.filter((r) => r.isActive());
		}
		return [];
	}, [rentals]);

	const pastRentals = useMemo<RentalWrapper[]>(() => {
		if (rentals) {
			return rentals.filter((r) => !r.isActive());
		}
		return [];
	}, [rentals]);

	return (
		<MobileContainer showNav={true}>
			{!rentals && (
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Loading />
					</Grid>
				</Grid>
			)}
			{rentals && (
				<Fragment>
					{rentals.length == 0 && (
						<FullScreenCard
							actions={
								<Box>
									<Typography align="center">
										<Button
											variant="contained"
											color="primary"
											onClick={() => navigate(`/tenants/${tenantId}`)}
										>
											{t("site.bookNow")}
										</Button>
									</Typography>
								</Box>
							}
						>
							<Box p={2} paddingTop={6}>
								<Grid container spacing={gs}>
									<Grid item xs={12}>
										<Typography align="center">
											<Help
												color="secondary"
												sx={{
													width: "8em",
													height: "8em"
												}}
											/>
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="h3" align="center">
											{t("rentals.noRentals")}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="body1" align="center">
											{t("rentals.noRentalsInfo")}
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</FullScreenCard>
					)}
					{rentals.length > 0 && (
						<Box p={2}>
							<Grid container spacing={gs}>
								{currentRentals.map((r) => (
									<Grid item xs={12} key={r.rental.uuid} style={{borderRadius: "8px"}}>
										<RentalPaper rental={r} />
									</Grid>
								))}
								{pastRentals.length > 0 && (
									<Fragment>
										<Grid item xs={12}>
											<Divider />
										</Grid>
										<Grid item xs={12}>
											<Typography variant="h4" align="center">
												{t("rentals.pastRentals")}
											</Typography>
										</Grid>
										{pastRentals.map((r) => (
											<Grid item xs={12} key={r.rental.uuid} style={{borderRadius: "8px"}}>
												<RentalPaper rental={r} />
											</Grid>
										))}
									</Fragment>
								)}
							</Grid>
						</Box>
					)}
				</Fragment>
			)}
		</MobileContainer>
	);
}

interface RentalPaperProps {
	rental: RentalWrapper;
}

function useItemImage(itemUuid: string): {alt?: string; src?: string} {
	const {api} = useAuthContext();
	const {tenantId} = useTenantContext();

	const {result} = useAsync(
		async (itemUuid: string) => {
			if (api && api.auth && tenantId && itemUuid) {
				const itemsProvider = new ItemsProvider(api);
				const item = await itemsProvider.get(tenantId, itemUuid);
				const src = new ItemWrapper(item).imageUrl;
				if (src) {
					return {
						alt: item.name,
						src: src
					};
				}
			}
		},
		[itemUuid]
	);

	return result ?? {};
}

function RentalPaper(props: RentalPaperProps) {
	const {rental} = props;
	const {tenantId} = useTenantContext();
	const {t} = useLocalization();

	const {alt, src} = useItemImage(rental.rental.itemUuid);

	return (
		<Card sx={{display: "flex"}}>
			<CardMedia component="img" sx={{width: 160, maxHeight: 240, minHeight: 240}} image={src} alt={alt} />
			<Box p={2}>
				<Stack spacing={1}>
					<Box>
						<Typography variant="h4">{rental.rental.itemName}</Typography>
						<Typography variant="caption" color="textSecondary">
							{t("rentals.createdAt")}: <TemporalFormat value={rental.rental.created} />
						</Typography>
					</Box>
					{rental.isActive() && (
						<Box>
							<Typography variant={"body2"}>{t("rentals.timeRemaining")}:</Typography>
							<RemainingTime rental={rental.rental} fontSize="xx-large"></RemainingTime>
						</Box>
					)}
					{!rental.isActive() && (
						<Box>
							<Typography variant="body2">
								<TemporalRangeFormat from={rental.rental.from} until={rental.rental.until} />
							</Typography>
						</Box>
					)}
					<Box>
						<Button
							variant="contained"
							color="primary"
							component={Link}
							to={`/tenants/${tenantId}/rentals/${rental.rental.uuid}`}
						>
							{t("rentals.showMore")}
						</Button>
					</Box>
				</Stack>
			</Box>
		</Card>
	);
}
