import {SignUpRequest} from "./types";
import {Api} from "../libs/Api";

export function useSignUpApi() {
	const api = new Api('/');
	return {
		signup: async (request: SignUpRequest) => {
			return api.post("/registration", request)
		},
	}
}
