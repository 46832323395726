import {
	Avatar,
	Box,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import {createElement, Fragment} from "react";
import {gs} from "../../../consts";
import {Cube} from "../../../domain/cubes";
import {Item} from "../../../domain/items";
import {Rental} from "../../../domain/rentals";
import {useLocalization} from "../../../i18n";
import {VCBox} from "../../../layout/Box";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {MobileContainer} from "../../../layout/MobileContainer";

interface EndRentalScreenProps {
	item: Item;
	rental: Rental;
	cube: Cube;
	onBack: (value: boolean) => void;
	handleOpenBox: () => void;
}

export function EndRentalInstructionsScreen(props: EndRentalScreenProps) {
	const {item, rental, onBack, cube, handleOpenBox} = props;
	const {t} = useLocalization();
	const theme = useTheme();

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard
				onBack={() => onBack(false)}
				actions={
					<Fragment>
						{cube?.connected === true && (
							<Button variant="contained" color="primary" fullWidth onClick={handleOpenBox}>
								{t("actions.openbox")}
							</Button>
						)}
						{cube?.connected === false && (
							<Stack
								sx={{
									justifyContent: "center",
									alignItems: "center"
								}}
							>
								<Typography gutterBottom>
									Please use this code on the keypad to open the locker:
								</Typography>
								<Typography variant="h3">
									<strong>0{rental.borrower?.accessCode}</strong>
								</Typography>
							</Stack>
						)}
					</Fragment>
				}
				paddingTop={6}
			>
				<Stack p={2} spacing={gs}>
					<Typography variant="h1" align="center">
						{t("rentals.return.title")}
					</Typography>
					<List>
						<ListItem divider sx={{py: 3}}>
							<ListItemIcon>
								<Avatar sx={{bgcolor: theme.palette.secondary.main}}>1</Avatar>
							</ListItemIcon>
							<ListItemText primary={t("rentals.return.removedPrivateItems")} />
						</ListItem>
						<ListItem divider sx={{py: 3}}>
							<ListItemIcon>
								<Avatar sx={{bgcolor: theme.palette.secondary.main}}>2</Avatar>
							</ListItemIcon>
							<ListItemText primary={t("rentals.return.putEverythingBack")} />
						</ListItem>
						<ListItem divider sx={{py: 3}}>
							<ListItemIcon>
								<Avatar sx={{bgcolor: theme.palette.secondary.main}}>3</Avatar>
							</ListItemIcon>
							<ListItemText primary={t("rentals.return.closeBox")} />
						</ListItem>
					</List>
					<Box>{item && item.stored && <VCBox boxNumber={item?.stored?.boxNumber} />}</Box>
				</Stack>
			</FullScreenCard>
		</MobileContainer>
	);
}
