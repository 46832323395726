import {Button, Grid, Typography} from "@mui/material";
import * as React from "react";
import {createElement} from "react";
import faceKissWinkHeartRegular from "../../../assets/icons/face-kiss-wink-heart-regular.svg";
import {gs} from "../../../consts";
import {LargeIconDisplay} from "../../../displays/LargeIconDisplay";
import {useLocalization} from "../../../i18n";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {MobileContainer} from "../../../layout/MobileContainer";
import {PoweredByVariocube} from "../../../layout/PoweredByVariocube";

interface ThanksScreenProps {
	onContinue: () => void;
}

export function ThanksScreen(props: ThanksScreenProps) {
	const {onContinue} = props;
	const {t} = useLocalization();

	return (
		<MobileContainer showNav={false}>
			<FullScreenCard actions={<PoweredByVariocube />}>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<LargeIconDisplay src={faceKissWinkHeartRegular} title={t("rentals.thanks.title")} />
					</Grid>
					<Grid item xs={12}>
						<Typography align="center">
							<Button variant="contained" color="primary" onClick={onContinue}>
								{t("rentals.thanks.bookAgain")}
							</Button>
						</Typography>
					</Grid>
				</Grid>
			</FullScreenCard>
		</MobileContainer>
	);
}
