import {Instant, Now, ZonedDateTime} from "@variocube/app-ui";
import {Api} from "../libs/Api";
import {Page} from "./types";

export enum RentalState {
	Created = "Created",
	RequireApproval = "RequireApproval",
	BorrowerPickedUp = "BorrowerPickedUp",
	LenderPickedUp = "LenderPickedUp",
	LenderReturned = "LenderReturned",
	BorrowerReturned = "BorrowerReturned"
}

export interface Rental {
	uuid: string;
	itemUuid: string;
	itemName: string;
	created: Instant;
	from: Instant;
	until: Instant;
	state: RentalState;
	borrower?: {
		accessCode?: string;
	};
}

export class RentalWrapper {
	readonly rental: Rental;

	constructor(rental: Rental) {
		this.rental = rental;
	}

	isActive(): boolean {
		if (this.rental.state == RentalState.Created) {
			return (
				Instant.compare(Now.instant(), this.rental.from) >= 0 &&
				Instant.compare(Now.instant(), this.rental.until) < 0
			);
		} else if (this.rental.state == RentalState.BorrowerPickedUp) {
			return true;
		}
		return false;
	}

	canKeep(): boolean {
		if (!this.isActive()) {
			return false;
		}
		return Instant.compare(Now.instant(), this.rental.until) < 0;
	}
}

export interface CreateRentalRequest {
	itemUuid: string;
	from: string;
	until: string;
}

export class RentalsProvider {
	readonly api: Api;

	constructor(api: Api) {
		this.api = api;
	}

	async list(tenantId: string): Promise<Page<Rental>> {
		const url = `/tenants/${tenantId}/rentals`;
		const page = await this.api.get<Page<Rental>>(url, "application/vnd.rentals.paged+json");
		return {
			...page,
			content: page.content.map(mapRentalResponse)
		};
	}

	async get(tenantId: string, rentalUuid: string): Promise<Rental> {
		const url = `/tenants/${tenantId}/rentals/${rentalUuid}`;
		return this.api.get<Rental>(url).then(mapRentalResponse);
	}

	async createRental(tenantId: string, itemUuid: string, from: ZonedDateTime, until: ZonedDateTime): Promise<Rental> {
		const url = `/tenants/${tenantId}/rentals`;
		const request: CreateRentalRequest = {
			itemUuid,
			from: from.toInstant().toString(),
			until: until.toInstant().toString()
		};
		return this.api.post<Rental>(url, request).then(mapRentalResponse);
	}

	async endRental(tenantId: string, rentalUuid: string): Promise<Rental> {
		const url = `/tenants/${tenantId}/rentals/${rentalUuid}/end`;
		return this.api.put<Rental>(url, {}).then(mapRentalResponse);
	}

	async openRentalBox(tenantId: string, rentalUuid: string, endRental: boolean): Promise<Rental> {
		const url = `/tenants/${tenantId}/rentals/${rentalUuid}/open`;
		const request = {
			endRental
		};
		return this.api.post<Rental>(url, request).then(mapRentalResponse);
	}
}

function mapRentalResponse(r: any): Rental {
	return {
		...r,
		created: Instant.from(r.created),
		from: Instant.from(r.from),
		until: Instant.from(r.until)
	};
}
