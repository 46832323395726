import { SiteAccessibility } from "@variocube/app-ui";
import {Api} from "../libs/Api";
import {Page} from "./types";

export interface Cube {
	cubeId: string;
	description: string;
	connected: boolean;
	latitude: number;
	longitude: number;
	address?: {
		street?: string;
		houseNo?: string;
		zip?: string;
		city?: string;
		country?: string;
	};
	site?: string;
}

export class CubeWrapper {
	readonly cube: Cube;

	constructor(cube: Cube) {
		this.cube = cube;
	}

	get mapsUrl(): string | undefined {
		if (this.cube.latitude && this.cube.longitude) {
			return `https://www.google.com/maps/search/?api=1&query=${this.cube.latitude},${this.cube.longitude}`;
		}
	}
}

export class CubesProvider {
	readonly api: Api;

	constructor(api: Api) {
		this.api = api;
	}

	async list(tenantId: string): Promise<Page<Cube>> {
		const url = `/tenants/${tenantId}/cubes`;
		return this.api.get<Page<Cube>>(url);
	}

	async get(tenantId: string, cubeId: string): Promise<Cube> {
		const url = `/tenants/${tenantId}/cubes/${cubeId}`;
		return this.api.get<Cube>(url);
	}

	async getStorageItem(tenantId: string, cubeId: string, storageItemKey: string): Promise<{key: string, value: string}> {
		const url = `/tenants/${tenantId}/cubes/${cubeId}/storageitems/${storageItemKey}`;
		return this.api.get<{key: string, value: string}>(url);
	} 
}

export function cubeImage(tenantId: string, cubeId: string): string {
	// TODO fetch this from CAC in the future
	return `https://variocube-docs.s3.eu-west-1.amazonaws.com/variocube.lend/${tenantId}/${cubeId}.jpg`;
}
