import {createElement, PropsWithChildren} from "react";
import {gs} from "../consts";
import {Box, Grid, Paper} from "@mui/material";
import {Logo} from "@variocube/app-ui";

interface InfoCardProps extends PropsWithChildren<{}> {}

export function InfoCard(props: InfoCardProps) {
	const {children} = props;

	return (
		<Grid container spacing={gs}>
			<Grid item xs={12}>
				<Box paddingLeft={5} paddingTop={5} paddingRight={5} display="flex" justifyContent="center">
					<Logo />
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box p={2}>
					<Paper>{children}</Paper>
				</Box>
			</Grid>
		</Grid>
	);
}
