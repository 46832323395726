import {createElement, ReactElement, useMemo} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {SvgIconDisplay} from "./SvgIconDisplay";

interface LargeIconDisplayProps {
	src?: string;
	icon?: ReactElement;
	title: string;
	info?: string;
	size?: number;
}

export function LargeIconDisplay(props: LargeIconDisplayProps) {
	const {src, icon, title, info, size = 14} = props;

	const sizeStyle = useMemo<string>(() => `${size}em`, [size]);

	return (
		<Box
			paddingTop={5}
			paddingBottom={5}
			paddingLeft={3}
			paddingRight={3}
			display="flex"
			flexDirection="column"
			alignItems="center"
		>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<Typography align="center">
						{icon}
						{src && (
							<SvgIconDisplay src={src} alt={title} style={{width: sizeStyle, height: sizeStyle}} />
						)}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h2" align="center">
						{title}
					</Typography>
				</Grid>
				{info && (
					<Grid item xs={12}>
						<Typography variant="body2" align="center">
							{info}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Box>
	);
}
