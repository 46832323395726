import {createElement, ReactElement} from "react";
import {Typography as MuiTypography,type TypographyProps as MuiTypographyProps} from "@mui/material";

type TypographyProps = MuiTypographyProps & {
	startAdornment?: ReactElement;
	endAdornment?: ReactElement;
}

export function Typography({startAdornment, endAdornment, children, sx = {}, ...props}: TypographyProps) {
	return (
		<MuiTypography
			{...props}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				// justifyContent: 'center',
				alignItems: 'center',
				gap: 1.5,
				...sx,
			}}
		>
			{startAdornment !== undefined && startAdornment}
			{children}
			{endAdornment !== undefined && startAdornment}
		</MuiTypography>
	)
}
