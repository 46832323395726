import {Box, Typography} from "@mui/material";
import {createElement, SVGProps} from "react";

interface VCBoxProps {
	boxNumber: string;
}

export function VCBox(props: VCBoxProps) {
	const {boxNumber} = props;

	return (
		<Box position="relative" margin="0 auto" width={100}>
			<BoxIcon width={100} height={90} />
			<Box position="absolute" left={0} width={90} bottom={0}>
				<Typography component="div" fontSize={64} fontWeight="bold" textAlign="center">
					{boxNumber}
				</Typography>
			</Box>
		</Box>
	);
}

function BoxIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 149" {...props}>
			<g fill="currentColor">
				<path
					d="M342.64,370.44v121h-121v-121h121m4-4h-129v129h129v-129Z"
					transform="translate(-217.64 -346.44)"
				/>
				<polygon points="158 0 32 0 0 15.94 130 15.94 158 0" />
				<polygon points="160 129 132.89 149 132.89 20 160 4 160 129" />
			</g>
		</svg>
	);
}
