import {LocationOn, Route} from "@mui/icons-material";
import {alpha, Box, Card, CardMedia, Fab, Grid, Typography, useTheme} from "@mui/material";
import {createElement, useEffect} from "react";
import {gs} from "../../../consts";
import {useSiteContext} from "../../../context/SiteContextProvider";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {AddressDisplay} from "../../../displays/AddressDisplay";
import {Cube, cubeImage, CubeWrapper} from "../../../domain/cubes";
import {useLocalization} from "../../../i18n";
import {BackButton} from "../../../layout/BackButton";

interface SiteSummaryProps {
	cube: Cube;
}

export function SiteSummary(props: SiteSummaryProps) {
	const {cube} = props;
	const {tenantId} = useTenantContext();
	const {cubeId} = useSiteContext();
	const {t} = useLocalization();
	const theme = useTheme();

	const bg = alpha(theme.palette.background.paper, 0.85);

	const mapsUrl = new CubeWrapper(cube).mapsUrl;

	return (
		<Card
			sx={{
				position: "relative",
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
				borderBottomLeftRadius: theme.spacing(1.5),
				borderBottomRightRadius: theme.spacing(1.5)
			}}
		>
			<CardMedia component="img" height="220" image={cubeImage(tenantId, cubeId)} alt={cube.description} />
			<Box position="absolute" bottom={0} p={2} width="100%" bgcolor={bg}>
				<Grid container spacing={gs} alignItems="center">
					<Grid item xs={2}>
						<LocationOn color="secondary" fontSize="large" />
					</Grid>
					<Grid item xs={10}>
						<Typography variant="h4">{cube.description}</Typography>
						<Typography>{cube.address && <AddressDisplay address={cube.address} singleLine />}</Typography>
					</Grid>
				</Grid>
			</Box>
			<BackButton padding={2} />
			{mapsUrl && (
				<Box position="absolute" top={theme.spacing(2)} right={theme.spacing(2)}>
					<Fab title={t("site.route")} color="primary" href={mapsUrl} target="_blank">
						<Route />
					</Fab>
				</Box>
			)}
		</Card>
	);
}
