import {Grid, useMediaQuery, useTheme} from "@mui/material";
import * as React from "react";
import {createElement, PropsWithChildren, useMemo} from "react";
import {gs} from "../consts";
import {BottomNav} from "./BottomNav";

interface MobileContainerProps
	extends PropsWithChildren<{
		showNav: boolean;
	}> {}

export function MobileContainer(props: MobileContainerProps) {
	const {showNav, children} = props;

	return <ActualContainer showNav={showNav}>{children}</ActualContainer>;
}

function ActualContainer(props: MobileContainerProps) {
	const {children, showNav} = props;

	const theme = useTheme();
	const isDesktop = useMediaQuery("(min-width:600px)");

	return (
		<>
			<div
				style={{
					backgroundColor: theme.palette.secondary.main,
					height: "100dvh",
					width: "100%",
					maxHeight: "800px",
					maxWidth: "480px",
					margin: isDesktop ? "auto auto auto auto" : "0 auto",
					position: "relative",
					flex: 1,
					display: "flex",
					flexDirection: "column"
				}}
			>
				<div
					style={{
						height: "100%",
						width: "100%",
						overflowY: "scroll",
						backgroundColor: theme.palette.secondary.main,
						color: "#fff"
					}}
				>
					{children}
				</div>
				{!isDesktop && showNav && <div style={{height: "2.5em"}}></div>}
				{showNav && <BottomNav />}
			</div>
		</>
	);
}
