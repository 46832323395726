import {AuthenticatedToken} from "../domain/auth";
import {PageChange} from "../domain/types";

const baseHeaders = {
	"Content-Type": "application/json"
};

function computeBaseUrl() {
	if (process.env.REACT_APP_API_ENDPOINT) {
		console.log(`Using configured API endpoint: ${process.env.REACT_APP_API_ENDPOINT} from .env`);
		return process.env.REACT_APP_API_ENDPOINT;
	}
	const hostname = window.location.hostname;
	switch (hostname) {
		case "localhost":
		 	return "/api/v1";
		case "lend-dev.variocube.com":
			return "https://rental-dev.variocube.com/api/v1";
		case "lend-test.variocube.com":
			return "https://rental-test.variocube.com/api/v1";
		default:
			return "https://rental.variocube.com/api/v1";
	}
}

export class Api {
	readonly auth?: AuthenticatedToken;
	readonly notAuthenticatedUrl: string;
	readonly apiBaseUrl: string;

	constructor(notAuthenticatedUrl: string, auth?: AuthenticatedToken) {
		this.notAuthenticatedUrl = notAuthenticatedUrl;
		this.auth = auth;
		this.apiBaseUrl = computeBaseUrl();
	}

	private buildUrl(path: string) {
		if (!path.startsWith("/")) {
			path = "/" + path;
		}
		return this.apiBaseUrl + path;
	}

	private buildHeaders(paged?: string) {
		if (this.auth && this.auth.isLoggedIn) {
			return {
				Authorization: this.auth.authHeader,
				...baseHeaders,
				...(paged
					? {
							Accept: paged
						}
					: {})
			};
		}
		return baseHeaders;
	}

	private async handleResponse(response: Response) {
		if (response.status == 401) {
			window.location.href = this.notAuthenticatedUrl;
		}
		if (response.status == 200 || response.status == 201) {
			try {
				return await response.json();
			} catch (error) {
				console.warn("Body is empty. Return nothing.");
				return "";
			}
		}
		const error = await response.json();
		throw new Error(error.message);
	}

	async fetch<RT>(path: string, method?: string, body?: any, paged?: string): Promise<RT> {
		const url = this.buildUrl(path);
		const response = await fetch(url, {
			method: method || "GET",
			body: body ? JSON.stringify(body) : null,
			headers: new Headers(this.buildHeaders(paged))
		});
		return (await this.handleResponse(response)) as RT;
	}

	toPagingParams(page: PageChange): string {
		return `page=${page.page}&size=${page.pageSize}`;
	}

	appendPagingParams(url: string, page: PageChange): string {
		return url + (url.includes("?") ? "&" : "?") + this.toPagingParams(page);
	}

	async get<RT>(path: string, paged?: string): Promise<RT> {
		return this.fetch<RT>(path, "GET", undefined, paged);
	}

	async post<RT>(path: string, body: any): Promise<RT> {
		return this.fetch<RT>(path, "POST", body);
	}

	async put<RT>(path: string, body: any): Promise<RT> {
		return this.fetch<RT>(path, "PUT", body);
	}

	async delete<RT>(path: string, body?: any): Promise<RT> {
		return this.fetch<RT>(path, "DELETE", body);
	}
}
