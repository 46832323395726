import {TabContext, TabPanel} from "@mui/lab";
import {Box, Button, Divider, Grid, Paper, Tab, Tabs, Typography} from "@mui/material";
import {SiteAccessibility} from "@variocube/app-ui";
import {Now} from "@variocube/app-ui/esm/temporal";
import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Link, useNavigate} from "react-router-dom";
import {gs} from "../../../consts";
import {useAuthContext} from "../../../context/AuthContextProvider";
import {useSiteContext} from "../../../context/SiteContextProvider";
import {useTenantContext} from "../../../context/TenantContextProvider";
import {FittingImage} from "../../../displays/FittingImage";
import {CubesProvider} from "../../../domain/cubes";
import {Item, ItemsProvider} from "../../../domain/items";
import {Model, ModelsProvider, ModelWrapper} from "../../../domain/models";
import {useLocalization} from "../../../i18n";
import {DurationInput} from "../../../inputs/DurationInput";
import {FullScreenCard} from "../../../layout/FullScreenCard";
import {Loading} from "../../../layout/Loading";
import {MobileContainer} from "../../../layout/MobileContainer";
import {ShortenedDisplay} from "../../../layout/ShortenedDisplay";
import {getUntil} from "../../../utils/TimeUtils";
import {RentalConditions} from "../rentals/RentalConditions";
import {SiteSummary} from "../sites/SiteSummary";

export function ModelsPage() {
	const {t} = useLocalization();
	const {api} = useAuthContext();
	const {tenantId, tenant} = useTenantContext();
	const {cubeId, cube} = useSiteContext();
	const [accessibility, setAccessibility] = useState<SiteAccessibility>();

	const [models, setModels] = useState<Model[]>();
	const [availableItems, setAvailableItems] = useState<Item[]>();
	const [modelsWithItems, setModelsWithItems] = useState<Model[]>();

	const [duration, setDuration] = useState<number>(120);

	const modelsProvider = new ModelsProvider(api);
	const itemsProvider = new ItemsProvider(api);

	const [tab, setTab] = useState("1");

	useEffect(() => {
		if (cubeId) {
			const cubeProvider = new CubesProvider(api);
			cubeProvider.getStorageItem(tenantId, cubeId, "accessibility").then((storageItemAccessibility) => {
				setAccessibility(JSON.parse(storageItemAccessibility.value));
			});
		}
	}, [tenantId, cubeId]);

	useEffect(() => {
		if (api && api.auth && tenantId && cubeId) {
			setModelsWithItems(undefined);
			modelsProvider.list(tenantId, cubeId).then(setModels);
			const from = Now.instant().toZonedDateTimeISO("Europe/Vienna");
			// const until = from.add(Duration.from({minutes: duration}));
			const until = getUntil(from, accessibility, duration);
			itemsProvider.availableItems(tenantId, from, until).then((r) => setAvailableItems(r.content));
		}
	}, [api, tenantId, cubeId, duration, accessibility]);

	useEffect(() => {
		setModelsWithItems(
			models?.filter(
				(m) =>
					availableItems &&
					availableItems.filter((i) => i.model?.uuid === m.uuid && i?.stored && i.stored.cubeId === cubeId)
						.length > 0
			)
		);
	}, [models, availableItems]);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue);
	};

	return (
		<MobileContainer showNav={true}>
			<Helmet>
				<title>{cube ? cube.description : t("site.bookNow")}</title>
			</Helmet>
			{cube && <SiteSummary cube={cube} />}
			{!cube && <Loading />}

			<FullScreenCard actions={null} noOverflow>
				<TabContext value={tab}>
					{/*
						<Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" mat-align-tabs="center">
							<Tab label={t("models.nowAvailable")} value="1"></Tab>
							<Tab label={t("models.preorder")} value="2" disabled></Tab>
						</Tabs>
					*/}

					<TabPanel value="1">
						<RentalConditions
							onConfirmConditions={(conditions) => setAvailableItems([])}
						></RentalConditions>
						<Grid container spacing={gs}>
							{!accessibility && (
								<>
									<Grid item xs={6}>
										<DurationInput
											duration={duration}
											onChange={setDuration}
											maxFutureMinutes={
												tenant?.maxRentPeriod ? tenant.maxRentPeriod * 60 : undefined
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<Divider />
									</Grid>
								</>
							)}
							{!modelsWithItems && (
								<Grid item xs={12}>
									<Loading />
								</Grid>
							)}
							{modelsWithItems && (
								<>
									{modelsWithItems.map((model) => (
										<Grid item xs={6} key={model.uuid}>
											<ModelView model={model} />
										</Grid>
									))}
									{modelsWithItems.length == 0 && (
										<>
											<Grid item xs={12}>
												<Typography variant="h3" align="center">
													{t("site.noItems")}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="body1">{t("site.noItemsInfo")}</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography align="center">
													<Button
														variant="contained"
														color="primary"
														component={Link}
														to={`/tenants/${tenantId}/imprint`}
													>
														{t("support")}
													</Button>
												</Typography>
											</Grid>
										</>
									)}
								</>
							)}
						</Grid>
					</TabPanel>
					<TabPanel value="2">{t("notAvailable")}</TabPanel>
				</TabContext>
			</FullScreenCard>
		</MobileContainer>
	);
}

interface ModelViewProps {
	model: Model;
}

function ModelView(props: ModelViewProps) {
	const {model} = props;

	const navigate = useNavigate();
	const {tenantId} = useTenantContext();
	const {cubeId} = useSiteContext();
	const {t} = useLocalization();

	return (
		<Paper>
			<Grid item xs={12}>
				<Box p={1}>
					<Typography
						variant="h6"
						style={{
							fontSize: "medium",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							overflowX: "hidden"
						}}
					>
						<ShortenedDisplay value={model.name} maxLen={15} />
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<div style={{height: "7em"}}>
					<FittingImage src={new ModelWrapper(model).imageUrl} />
				</div>
			</Grid>
			<Button
				variant="contained"
				color="primary"
				disableElevation
				fullWidth
				onClick={() => navigate(`/tenants/${tenantId}/sites/${cubeId}/models/${model.uuid}`)}
				sx={{borderRadius: "0 0 4px 4px"}}
			>
				{t("site.bookNow")}
			</Button>
		</Paper>
	);
}
